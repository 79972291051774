import React, {useContext, useEffect, useState} from 'react'
import {useQuery, gql, useMutation} from '@apollo/client'
import {Modal, Button, FormInput, Avatar, FormCombobox, FormTextarea, Banner, Checkbox} from '../tailwind'
import {FormElement} from '../tailwind/form-element'
import {UsersRoomsAndTagsQuery} from '../../graphql/UsersRoomsAndTagsQuery'
import {Appointment, hasAnyRole, Role, Room, Status, Tag, User} from '../../models'
import {AppContext} from '../../context'
import {AddAppointmentMutationVariables} from '../../graphql/AddAppointmentMutation'
import {APPOINTMENT_SELECT_FRAGMENT} from '../../queries'
import {usePersistedState} from '../../hooks'
import Spinner from '../spinner'
import {AppointmentEventInput} from '../../graphql/Global'
import {isAccessible, shadeColor} from '../../utils/color'
import {ChevronDownIconSmall} from '../icon'
import {formatSeconds} from '../stats'

type Time = {
  display: string
  value: number
}

const getTime = (hours: number, minutes: number): Time => {
  const date = new Date()
  const value = date.setHours(hours, minutes)

  return {
    display: date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}),
    value: Math.floor(value / 1000),
  }
}

const interval = 5
const startHour = 7
const endHour = 18
const times: Time[] = [...new Array(endHour - startHour).keys()].flatMap((hour) => {
  return [...new Array(60 / interval).keys()].map((minutes) => getTime(hour + startHour, minutes * interval))
})

interface TagToggleProps extends React.HTMLAttributes<HTMLElement> {
  tag: Tag
  selected?: boolean
  readonly?: boolean
}

const TagToggle: React.FC<TagToggleProps> = ({tag, selected = false, readonly = false, onClick, ...props}) => {
  return (
    <button
      type="button"
      disabled={readonly}
      {...props}
      className={`py-1 border text-sm uppercase leading-5 font-medium rounded-sm focus:outline-none focus:border-indigo-700 focus:ring-indigo transition ease-in-out duration-150 ${
        tag.name?.length > 15 && 'col-span-2'
      } ${readonly && 'cursor-not-allowed'}`}
      style={{
        backgroundColor: selected ? tag.color : 'white',
        borderColor: tag.color,
        color: isAccessible(tag.color) ? (selected ? 'white' : tag.color) : shadeColor(tag.color, -40),
      }}
      onClick={onClick}
    >
      {tag.name}
    </button>
  )
}

interface TagSelectorProps extends React.ComponentProps<'div'> {
  tags: Tag[]
  selected: string[]
  readonly?: boolean
  onSelectionChanged: (selected: string[]) => void
}

const TagSelector: React.FC<TagSelectorProps> = ({
  tags,
  selected,
  onSelectionChanged,
  readonly = false,
  className,
  ...props
}) => {
  const [showAll, setShowAll] = useState(false)

  const sortedTags = tags?.slice().sort((a, b) => a.level - b.level)

  const handleTagClick = (tag: Tag) => {
    if (readonly) return

    if (selected.includes(tag.id)) {
      const newSelected = selected.filter((id) => id !== tag.id)
      onSelectionChanged(newSelected)
    } else {
      const newSelected = [...selected, tag.id]
      onSelectionChanged(newSelected)
    }
  }

  const handleShowMoreClick = () => {
    setShowAll(true)
  }

  return (
    <div {...props} className={`grid grid-cols-2 sm:grid-cols-6 gap-2 relative ${className}`}>
      {sortedTags &&
        sortedTags.slice(0, showAll ? tags.length : Math.ceil(tags.length / 2)).map((tag) => {
          return (
            <TagToggle
              key={tag.id}
              tag={tag}
              readonly={readonly}
              selected={selected.includes(tag.id)}
              onClick={() => handleTagClick(tag)}
            />
          )
        })}
      {!showAll && (
        <span
          title="Show more"
          className="w-8 h-8 absolute z-auto bottom-0 left-1/2 transform translate-y-5 -translate-x-1/2 text-white rounded-full shadow-md bg-secondary"
        >
          <button
            type="button"
            className="w-full h-full flex items-center hover:opacity-75 focus:outline-none active:outline-none"
            onClick={handleShowMoreClick}
          >
            <ChevronDownIconSmall className="w-6 h-6 m-auto" />
          </button>
        </span>
      )}
    </div>
  )
}

const GET_DATA = gql`
  query UsersRoomsAndTagsQuery {
    tenant {
      id
      users {
        id
        name
        picture
        roles
      }
      rooms {
        id
        name
        order
        locationId
      }
      tags {
        id
        name
        color
        level
      }
    }
  }
`

const ADD_APPOINTMENT = gql`
  mutation AddAppointmentMutation($tid: String!, $input: AppointmentInput!) {
    addAppointment(tid: $tid, input: $input) {
      ...AppointmentSelectFragment
    }
  }
  ${APPOINTMENT_SELECT_FRAGMENT}
`

export enum PromptField {
  Room = 'Room',
  Provider = 'Provider',
  Requesting = 'Team member requesting',
  Notes = 'Notes',
}

export interface AppointmentEditorProps {
  appointment?: Appointment
  title?: string
  subtitle?: string
  promptFields?: PromptField[]
  providerFilter?: (user: User) => boolean
  prompt?: boolean
  onDismiss: (event?: AppointmentEventInput) => Promise<void>
  readonly?: boolean
}

// eslint-disable-next-line complexity
export const AppointmentEditor: React.FC<AppointmentEditorProps> = ({
  appointment,
  promptFields = [],
  providerFilter,
  title,
  subtitle,
  prompt = false,
  onDismiss,
  readonly = false,
  ...props
}) => {
  const isEditing = appointment !== undefined
  const status = appointment?.desiredStatus ?? appointment?.status
  const isPending = status === Status.PENDING

  const {data, loading} = useQuery<UsersRoomsAndTagsQuery>(GET_DATA) // loading, error
  const {users, tags, rooms, id: tid} = data?.tenant ?? {}

  const {currentLocation, user} = useContext(AppContext)
  const [addAppointment, {error: addError}] = useMutation<AddAppointmentMutationVariables>(ADD_APPOINTMENT)

  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<string>()
  const [patientError, setPatientError] = useState<string>()
  const [scheduledError, setScheduledError] = useState<string>()
  const [lengthError, setLengthError] = useState<string>()
  const [roomError, setRoomError] = useState<string>()
  const [requestingError, setRequestingError] = useState<string>()
  const [providerError, setProviderError] = useState<string>()
  const [tagsError, setTagsError] = useState<string>()
  const [notesError, setNotesError] = useState<string>()
  const [startTimeError, setStartTimeError] = useState<string>()
  const [endTimeError, setEndTimeError] = useState<string>()

  useEffect(() => {
    setError(addError?.message)
  }, [addError])

  const sortedRooms: Room[] = rooms
    ?.filter((r) => r.locationId === currentLocation?.id)
    .slice()
    .sort((a, b) => a.order - b.order)

  const [persistedRequesting, setPersistedRequesting] = usePersistedState<User>('requesting')
  const [persistedLength, setPersistedLength] = usePersistedState<number>('length')

  const currentSeconds = Date.now() / 1000
  const referenceSeconds = appointment?.scheduled ?? currentSeconds
  const [initialScheduled] = useState<Time>(
    times
      .filter((t: Time): boolean => (appointment?.scheduled ? true : t.value > currentSeconds))
      .slice()
      .sort((a, b) => Math.abs(referenceSeconds - a.value) - Math.abs(referenceSeconds - b.value))[0]
  )
  const providerFilteredOut = providerFilter && !providerFilter(appointment?.provider)
  const initialProvider = providerFilteredOut ? undefined : appointment?.provider

  const [patient, setPatient] = useState<string>(isEditing ? appointment.patient : '')
  const [requesting, setRequesting] = useState<User>(
    appointment?.requesting ?? (isPending ? undefined : persistedRequesting)
  )
  const [provider, setProvider] = useState<User>(initialProvider)
  const [room, setRoom] = useState<Room>(appointment?.room)
  const [scheduled, setScheduled] = useState<number>(isEditing ? appointment.scheduled : initialScheduled?.value)
  const [length, setLength] = useState<number>(appointment?.length ?? persistedLength)
  const [selectedTags, setSelectedTags] = useState<string[]>(isEditing ? appointment.tags?.map((t) => t.id) ?? [] : [])
  const [notes, setNotes] = useState<string>(isEditing ? appointment.notes : '')
  const [important, setImportant] = useState(isEditing ? appointment.important : false)
  const [startTime, setStartTime] = useState(isEditing ? new Date(appointment.actualStart * 1000) : undefined)
  const [endTime, setEndTime] = useState(isEditing ? new Date(appointment.lastStatusChangedAt * 1000) : undefined)
  const [duration, setDuration] = useState(isEditing ? appointment.actualDuration : undefined)

  const teamMembers: User[] =
    users
      ?.slice()
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.filter((user) => user.roles.includes(Role.TEAM)) ?? []
  const defaultProviders: User[] =
    users?.filter(
      (user) =>
        user.roles.includes(Role.DOCTOR) ||
        user.roles.includes(Role.HYGIENE) ||
        user.roles.includes(Role.TREATMENT) ||
        user.roles.includes(Role.ASSISTANT)
    ) ?? []

  const providers = providerFilter ? defaultProviders.filter((provider) => providerFilter(provider)) : defaultProviders

  const providerGroups: Record<string, (user: User) => boolean> = {
    'First Available': (u) => u.roles.includes(Role.FIRST_AVAILABLE),
    Doctors: (u) => u.roles.includes(Role.DOCTOR) && !u.roles.includes(Role.NON_USER),
    Hygienists: (u) => u.roles.includes(Role.HYGIENE) && !u.roles.includes(Role.NON_USER),
    'Treatment Coordinators': (u) => u.roles.includes(Role.TREATMENT) && !u.roles.includes(Role.NON_USER),
  }

  const roomRequired = promptFields.includes(PromptField.Room)
  const providerRequired = promptFields.includes(PromptField.Provider)
  const requestingRequired = promptFields.includes(PromptField.Requesting)
  const notesRequired = promptFields.includes(PromptField.Notes)

  const firstMissing =
    roomRequired && !room
      ? PromptField.Room
      : requestingRequired && !requesting
      ? PromptField.Requesting
      : providerRequired && !provider
      ? PromptField.Provider
      : PromptField.Notes

  const handlePatientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPatient(event.target?.value)
  }

  const handleRequestingChange = (user: User) => {
    setRequesting(user)
    setPersistedRequesting(user)
  }

  const handleProviderChange = (user: User) => {
    setProvider(user)
  }

  const handleRoomChange = (room: Room) => {
    setRoom(room)
  }

  const handleTimeChange = (time: Time) => {
    setScheduled(time?.value)
  }

  const handleLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseInt(event.target?.value, 10)
    setLength(value)
    setPersistedLength(value)
  }

  const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [day, month, date, year, , ...timezone] = new Date(scheduled * 1000).toString().split(' ')
    const newStartTime = `${day} ${month} ${date} ${year} ${event.target?.value}:00 ${timezone.join(' ')}`
    const newStartTimeDate = new Date(newStartTime)
    setStartTime(newStartTimeDate)
    setDuration((endTime.getTime() - newStartTimeDate.getTime()) / 1000)
  }

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [day, month, date, year, , ...timezone] = new Date(scheduled * 1000).toString().split(' ')
    const newEndTime = `${day} ${month} ${date} ${year} ${event.target?.value}:00 ${timezone.join(' ')}`
    const newEndTimeDate = new Date(newEndTime)
    setEndTime(newEndTimeDate)
    setDuration((newEndTimeDate.getTime() - startTime.getTime()) / 1000)
  }

  const handleTagSelectionChanged = (selection: string[]) => {
    setSelectedTags(selection)
  }

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target?.value)
  }

  const handlePriorityChange = (value: boolean) => {
    setImportant(value)
  }

  const add = async () => {
    await addAppointment({
      variables: {
        // We're setting `tid` for future subscription filtering
        tid,
        input: {
          patient,
          locationId: currentLocation?.id,
          requestingId: requesting?.id,
          scheduled,
          length,
          important,
          notes,
          tagIds: selectedTags,
          providerId: provider?.id,
          roomId: room?.id,
        },
      },
    })
  }

  // eslint-disable-next-line complexity
  const update = () => {
    const differentTags = selectedTags
      .filter((t) => !appointment.tagIds?.includes(t))
      .concat(appointment.tagIds?.filter((t) => !selectedTags?.includes(t))).length

    const input = {
      appointmentId: appointment.id,
      status: appointment.desiredStatus,
      patient: patient === appointment.patient ? undefined : patient,
      requestingId: requesting?.id === appointment.requestingId ? undefined : requesting?.id ?? '',
      scheduled: scheduled === appointment.scheduled ? undefined : scheduled,
      length: length === appointment.length ? undefined : length,
      important: important === appointment.important ? undefined : important,
      notes: notes === appointment.notes ? undefined : notes ?? '',
      providerId: provider?.id === appointment.providerId ? undefined : provider?.id ?? '',
      roomId: room?.id === appointment.roomId ? undefined : room?.id ?? '',
      tagIds: differentTags ? selectedTags : undefined,
      startTime:
        !appointment.completed || startTime.getTime() === appointment.actualStart * 1000
          ? undefined
          : Math.round(startTime.getTime() / 1000),
      endTime:
        !appointment.completed || endTime.getTime() === appointment.lastStatusChangedAt * 1000
          ? undefined
          : Math.round(endTime.getTime() / 1000),
    }

    if (
      input.status === undefined &&
      input.patient === undefined &&
      input.requestingId === undefined &&
      input.scheduled === undefined &&
      input.length === undefined &&
      input.important === undefined &&
      input.notes === undefined &&
      input.providerId === undefined &&
      input.roomId === undefined &&
      input.tagIds === undefined &&
      input.startTime === undefined &&
      input.endTime === undefined
    )
      return undefined

    return {...input, requesting, room, provider, tags: tags.filter((tag) => input.tagIds?.includes(tag.id))}
  }

  const invalid = (): boolean => {
    let hasError = false

    if (!patient) {
      setPatientError('Patient name is required')
      hasError = true
    }

    if (!scheduled) {
      setScheduledError('Scheduled time is required')
      hasError = true
    }

    if (!length) {
      setLengthError('Length is required')
      hasError = true
    }

    if (selectedTags.length > 3) {
      setTagsError('You can only select up to 3 tags')
      hasError = true
    }

    if (roomRequired && !room) {
      setRoomError('Room is required')
      hasError = true
    }

    if (requestingRequired && !requesting) {
      setRequestingError('Requesting is required')
      hasError = true
    }

    if (providerRequired && !provider) {
      setProviderError('Provider is required')
      hasError = true
    }

    if (notesRequired && !notes?.trim()) {
      setNotesError('Notes are required')
      hasError = true
    }

    if (startTime && startTime.getTime() > appointment.actualStart * 1000) {
      setStartTimeError(`Start Time cannot be after ${new Date(appointment.actualStart * 1000).toLocaleTimeString()}`)
      hasError = true
    }

    if (endTime && endTime.getTime() < appointment.lastStatusBeforeCompleteChangedAt * 1000) {
      setEndTimeError(
        `End Time cannot be before ${new Date(
          appointment.lastStatusBeforeCompleteChangedAt * 1000
        ).toLocaleTimeString()}`
      )
      hasError = true
    }

    return hasError
  }

  const handleSave = async (e: React.FormEvent) => {
    if (readonly) return

    e.preventDefault()

    setError(undefined)
    setPatientError(undefined)
    setScheduledError(undefined)
    setLengthError(undefined)
    setTagsError(undefined)
    setRoomError(undefined)
    setRequestingError(undefined)
    setProviderError(undefined)
    setNotesError(undefined)
    setStartTimeError(undefined)
    setEndTimeError(undefined)

    if (invalid()) return

    setBusy(true)

    if (isEditing) {
      const input: AppointmentEventInput = update()
      await onDismiss(input)
    } else {
      await add()
      await onDismiss()
    }

    setBusy(false)
  }

  const handleDismiss = () => {
    onDismiss()
  }

  const visual = (user: User) => (
    <div className="w-6">
      <Avatar user={user} size="tiny" />
    </div>
  )

  const canMarkAsHighPriority = hasAnyRole(user, Role.ADMIN, Role.DOCTOR) && !readonly

  return (
    <Modal {...props} size="large" onDismiss={handleDismiss}>
      <form autoComplete="off" onSubmit={handleSave}>
        <div>
          <div className="w-full">
            <div className="w-full flex justify-between items-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {title ?? `${prompt ? 'Move' : isEditing ? 'Edit' : 'New'} appointment`}
              </h3>
              {isEditing && (
                <span className="font-light text-gray-600">{`${appointment.patient} @ ${appointment.scheduledDisplay}${
                  appointment.completed ? ` on ${new Date(appointment.scheduled * 1000).toLocaleDateString()}` : ''
                }`}</span>
              )}
            </div>
            <div className="w-full flex justify-between items-center">
              <p className="mt-1 text-sm leading-5 text-gray-600">
                {subtitle ??
                  (prompt
                    ? 'Please provide the necessary info'
                    : `${isEditing ? 'Edit this' : 'Create a new'} appointment at the ${
                        currentLocation?.name
                      } location.`)}
              </p>
              {(canMarkAsHighPriority || isEditing) && (
                <Checkbox
                  id="priority"
                  disabled={!canMarkAsHighPriority}
                  defaultChecked={important}
                  onToggle={handlePriorityChange}
                >
                  High Priority
                </Checkbox>
              )}
            </div>
          </div>
          <div className="mt-8 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
            {!prompt && (
              <FormInput
                required
                disabled={readonly}
                autoFocus={!prompt}
                className="sm:col-span-2"
                label="Patient name"
                value={patient}
                error={patientError}
                onChange={handlePatientChange}
              />
            )}
            {!prompt &&
              (readonly ? (
                <FormInput
                  required
                  disabled
                  className="sm:col-span-2"
                  label="Scheduled time"
                  value={appointment.scheduledDisplay}
                />
              ) : (
                <FormCombobox
                  required
                  className="sm:col-span-2"
                  label="Scheduled time"
                  value="value"
                  display="display"
                  initial={initialScheduled}
                  items={times}
                  error={scheduledError}
                  onSelection={handleTimeChange}
                />
              ))}
            {!prompt && (
              <FormInput
                required
                disabled={readonly}
                type="number"
                className="sm:col-span-2"
                label="Length (minutes)"
                value={length}
                error={lengthError}
                onChange={handleLengthChange}
              />
            )}
            {!prompt && appointment?.completed && (
              <>
                <FormInput
                  disabled={readonly}
                  type="time"
                  className="sm:col-span-2"
                  label="Start Time"
                  value={startTime.toTimeString().split(' ')[0]}
                  error={startTimeError}
                  onChange={handleStartTimeChange}
                />
                <FormInput
                  disabled={readonly}
                  type="time"
                  className="sm:col-span-2"
                  label="End Time"
                  value={endTime.toTimeString().split(' ')[0]}
                  error={endTimeError}
                  onChange={handleEndTimeChange}
                />
                <FormInput disabled className="sm:col-span-2" label="Duration" value={formatSeconds(duration)} />
              </>
            )}
            {readonly ? (
              <FormInput disabled className="sm:col-span-2" label="Room" value={room?.name} />
            ) : (
              <FormCombobox
                autoFocus={prompt && firstMissing === PromptField.Room}
                className="sm:col-span-2"
                label="Room"
                value="id"
                display="name"
                initial={room}
                items={sortedRooms}
                required={promptFields.includes(PromptField.Room)}
                error={roomError}
                onSelection={handleRoomChange}
              />
            )}
            {readonly ? (
              <FormInput disabled className="sm:col-span-2" label="Team member requesting" value={requesting?.name} />
            ) : (
              <FormCombobox
                autoFocus={prompt && firstMissing === PromptField.Requesting}
                className="sm:col-span-2"
                label="Team member requesting"
                value="id"
                display="name"
                visual={visual}
                initial={requesting}
                items={teamMembers}
                required={promptFields.includes(PromptField.Requesting)}
                error={requestingError}
                onSelection={handleRequestingChange}
              />
            )}
            {readonly ? (
              <FormInput disabled className="sm:col-span-2" label="Provider" value={provider?.name} />
            ) : (
              <FormCombobox
                autoFocus={prompt && firstMissing === PromptField.Provider}
                className="sm:col-span-2"
                label="Provider"
                value="id"
                display="name"
                visual={visual}
                initial={provider}
                items={providers}
                groups={providerGroups}
                required={promptFields.includes(PromptField.Provider)}
                error={providerError}
                onSelection={handleProviderChange}
              />
            )}
            {!prompt && (
              <FormElement className="sm:col-span-6" label="Tags">
                <TagSelector
                  readonly={readonly}
                  tags={tags}
                  selected={selectedTags}
                  onSelectionChanged={handleTagSelectionChanged}
                />
                {tagsError && <p className="mt-2 text-sm text-red-600">{tagsError}</p>}
              </FormElement>
            )}
            <FormTextarea
              className="sm:col-span-6"
              disabled={readonly}
              label="Notes"
              value={notes}
              autoFocus={prompt && firstMissing === PromptField.Notes}
              required={promptFields.includes(PromptField.Notes)}
              error={notesError}
              onChange={handleNotesChange}
            />
          </div>
        </div>

        <div className="mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <Button
            fullWidth
            className="sm:col-start-2"
            busy={busy}
            disabled={busy || readonly}
            type="submit"
            onClick={handleSave}
          >
            Save
          </Button>
          <Button fullWidth secondary className="mt-3 sm:mt-0 sm:col-start-1" onClick={handleDismiss}>
            Cancel
          </Button>
        </div>

        {error && <Banner type="error" content={error} className="mt-3" />}
        {loading && (
          <div id="appointment-editor-loading-overlay" className="w-full h-full fixed block top-0 left-0 z-50">
            <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75" />
            <span className="w-full h-full fixed top-0 left-0 flex items-center">
              <Spinner className="opacity-100 w-24 h-24 m-auto" />
            </span>
          </div>
        )}
      </form>
    </Modal>
  )
}
