import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Button, Modal, ModalProps} from '../tailwind'
import {CheckCircleIcon, DocumentTextIcon} from '../icon'
import {useDropzone} from 'react-dropzone'
import {gql, useMutation, useSubscription} from '@apollo/client'
import {AppContext} from '../../context'
import {AddAppointmentImportMutation} from '../../graphql/AddAppointmentImportMutation'
import {AppointmentImportUpdatedSubscription} from '../../graphql/AppointmentImportUpdatedSubscription'
import {AppointmentImport} from '../../models'

const ADD_APPOINTMENT_IMPORT = gql`
  mutation AddAppointmentImportMutation($locationId: ID) {
    addAppointmentImport(locationId: $locationId) {
      id
      file {
        uploadUrl
      }
    }
  }
`

const APPOINTMENT_IMPORT_UPDATED_SUBSCRIPTION = gql`
  subscription AppointmentImportUpdatedSubscription {
    appointmentImportUpdated {
      id
      progress
      status
      skipped
      errors
      records
      importedIds
    }
  }
`

export const AppointmentImporter: React.FC<ModalProps> = ({onDismiss, ...props}) => {
  const [file, setFile] = useState<File>()
  const {currentLocation} = useContext(AppContext)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [appointmentImport, setAppointmentImport] = useState<AppointmentImport>()

  const {errors, importedIds, skipped, records} = appointmentImport ?? {}
  const uploadedProgress = `${Math.ceil(uploadProgress * 100)}%`
  const importedLength = importedIds?.length ?? 0
  const skippedLength = skipped?.length ?? 0
  const errorsLength = errors?.length ?? 0
  const recordsLength = records ?? 0

  const handled = importedLength + skippedLength + errorsLength
  const importedProgress = `${handled}/${recordsLength}`

  const importedPercent = Math.ceil((importedLength / records) * 100)
  const skippedPercent = Math.ceil((skippedLength / records) * 100)
  const errorsPercent = Math.ceil((errorsLength / records) * 100)

  const importedWidth = `${importedPercent}%`
  const skippedWidth = `${skippedPercent}%`
  const errorsWidth = `${errorsPercent}%`

  const complete = handled > 0 && handled === records

  const [addAppointmentImport] = useMutation<AddAppointmentImportMutation>(ADD_APPOINTMENT_IMPORT)
  const {data: statusData} = useSubscription<AppointmentImportUpdatedSubscription>(
    APPOINTMENT_IMPORT_UPDATED_SUBSCRIPTION
  )

  const handleDone = () => {
    onDismiss()
  }

  useEffect(() => {
    if (!statusData) return
    const {appointmentImportUpdated} = statusData
    if (appointmentImportUpdated.id !== appointmentImport?.id) return

    setAppointmentImport(appointmentImportUpdated)
  }, [appointmentImport?.id, statusData])

  const uploadFile = useCallback(async (url: string, file: File) => {
    await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {'Content-Type': ''},
    })

    setUploadProgress(1)
  }, [])

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles && acceptedFiles[0]) {
        const accepted = acceptedFiles[0]
        setFile(accepted)

        const {
          data: {addAppointmentImport: appointmentImport},
        } = await addAppointmentImport({variables: {locationId: currentLocation?.id}})

        setAppointmentImport(appointmentImport)

        const {
          file: {uploadUrl},
        } = appointmentImport

        await uploadFile(uploadUrl, accepted)
      }
    },
    [addAppointmentImport, currentLocation?.id, uploadFile]
  )

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: '.csv,.txt', maxFiles: 1})

  const dragZone = (
    <div
      className={`flex justify-center px-6 pt-5 pb-6 border-2 ${
        isDragActive ? 'border-secondary' : 'border-gray-400'
      } border-dashed rounded-md`}
      {...getRootProps()}
    >
      <div className="text-center">
        <input {...getInputProps()} />
        <DocumentTextIcon className="h-12 w-12 mx-auto text-gray-500" strokeWidth={1} />
        <p className="mt-1 text-sm text-gray-600">
          <button
            type="button"
            className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
          >
            Upload a file
          </button>
          <span className="ml-1">or drag and drop</span>
        </p>
        <p className="mt-1 text-xs text-gray-500">Comma-delimited CSV or TXT</p>
      </div>
    </div>
  )

  const progressElement = (
    <div>
      <div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
          <div>
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
              Uploading file
            </span>
          </div>
          <div className="text-right">
            <span className="text-xs font-semibold inline-block text-blue-600">{uploadedProgress}</span>
          </div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
          <div
            style={{width: uploadedProgress}}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
          />
        </div>
      </div>
      <div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
          <div>
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
              Importing appointments
            </span>
          </div>
          <div className="text-right">
            <span className="text-xs font-semibold inline-block text-blue-600">{importedProgress}</span>
          </div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
          <div
            style={{width: errorsWidth}}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
          />
          <div
            style={{width: skippedWidth}}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gray-500"
          />
          <div
            style={{width: importedWidth}}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
          />
        </div>
      </div>

      <div className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
        <div>
          <div className="px-4 py-5 sm:px-6 sm:py-2">
            <dl>
              <dt className="text-base leading-6 font-normal text-gray-900">Imported</dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                  {importedLength}
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="border-t border-gray-200 md:border-0 md:border-l">
          <div className="px-4 py-5 sm:px-6 sm:py-2">
            <dl>
              <dt className="text-base leading-6 font-normal text-gray-900">Skipped</dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-2xl leading-8 font-semibold text-gray-600">
                  {skippedLength}
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="border-t border-gray-200 md:border-0 md:border-l">
          <div className="px-4 py-5 sm:px-6 sm:py-2">
            <dl>
              <dt className="text-base leading-6 font-normal text-gray-900">Errors</dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-2xl leading-8 font-semibold text-red-600">{errorsLength}</div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <Modal {...props} onDismiss={onDismiss}>
      <div className="space-y-5">
        <div className="sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
          <div className="w-full">
            <div className="w-full flex justify-between items-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Import appointments</h3>
            </div>
            <p className="mt-1 text-sm leading-5 text-gray-600">Upload a comma-delimited file to import appointments</p>
          </div>
          {complete && <CheckCircleIcon className="text-green-500 w-10 h-10" />}
        </div>
        {file ? progressElement : dragZone}

        <div className="mt-8">
          <Button fullWidth secondary={!complete} className="sm:col-start-2" onClick={handleDone}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  )
}
