import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SkeletonQueue = ({skinny = false}) => (
  <div className="animate-pulse flex-1 space-y-4">
    <div className="h-8 bg-gray-300 rounded w-1/2 sm:w-1/6" />
    <div className="flex-1 space-y-1">
      <div className={`${skinny ? 'h-8' : 'h-24'} bg-gray-300 rounded w-full`} />
      <div className={`${skinny ? 'h-8' : 'h-24'} bg-gray-300 rounded w-full`} />
      {skinny && <div className="h-8 bg-gray-300 rounded w-full" />}
      {skinny && <div className="h-8 bg-gray-300 rounded w-full" />}
    </div>
  </div>
)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Skeleton = () => (
  <div className="flex-1 space-y-6">
    <SkeletonQueue skinny />
    <SkeletonQueue />
    <SkeletonQueue />
    <SkeletonQueue />
  </div>
)
