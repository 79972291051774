import {gql, useQuery} from '@apollo/client'
import React from 'react'
import {StatsPage, TagStatsSummary} from '../stats'
import {Skeleton} from '../skeleton'
import {Tag} from '../../models'
import {RouteComponentProps} from '@reach/router'
import SEOData from '../seo'
import {TagGetTagQuery} from '../../graphql/TagGetTagQuery'

export const GET_TAG = gql`
  query TagGetTagQuery($tagId: ID!) {
    tag(id: $tagId) {
      id
      name
      color
    }
  }
`

export const GET_STATS = gql`
  query TagGetTagStatsQuery($dateRange: DateRange, $tag: ID!) {
    stats(dateRange: $dateRange, tag: $tag) {
      id

      metric
      date
      updatedAt
      value

      provider {
        id
        picture
        name
        roles
      }

      tag {
        id
        name
        color
        level
      }
    }
  }
`

export interface TagStatsPageProps extends RouteComponentProps {
  tagId?: string
}

const TagStatsPage: React.FC<TagStatsPageProps> = ({tagId}) => {
  const {data} = useQuery<TagGetTagQuery>(GET_TAG, {
    variables: {tagId},
  })

  const tag = data?.tag as Tag
  if (!tag) return <Skeleton />

  const title = `${tag.name} Stats`

  return (
    <>
      <SEOData title={title} />
      <StatsPage
        title={title}
        breadcrumbs={[
          {name: 'Home', path: '/'},
          {name: 'Team Stats', path: '/team-stats/'},
        ]}
        query={GET_STATS}
        variables={{tag: tagId}}
        StatSummary={TagStatsSummary}
        emptyError="It looks like this tag doesn't have any stats"
        tag={tag}
      />
    </>
  )
}

export default TagStatsPage
