import React from 'react'

export const EmptyIllustration: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <div {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        data-name="Layer 1"
        viewBox="0 0 820.16 780.81"
      >
        <defs>
          <linearGradient
            x1="539.63"
            x2="539.63"
            y1="734.6"
            y2="151.19"
            gradientTransform="translate(-3.62 1.57)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="gray" stopOpacity="0.25" />
            <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
            <stop offset="1" stopColor="gray" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            x1="540.17"
            x2="540.17"
            y1="180.2"
            y2="130.75"
            gradientTransform="translate(-63.92 7.85)"
            xlinkHref="#07332201-7176-49c2-9908-6dc4a39c4716"
          />
          <linearGradient
            x1="540.17"
            x2="540.17"
            y1="140.86"
            y2="82.43"
            gradientTransform="rotate(-12.11 545.066 460.65)"
            xlinkHref="#07332201-7176-49c2-9908-6dc4a39c4716"
          />
          <linearGradient
            x1="476.4"
            x2="476.4"
            y1="710.53"
            y2="127.12"
            xlinkHref="#07332201-7176-49c2-9908-6dc4a39c4716"
          />
          <linearGradient
            x1="476.94"
            x2="476.94"
            y1="156.13"
            y2="106.68"
            xlinkHref="#07332201-7176-49c2-9908-6dc4a39c4716"
          />
          <linearGradient
            x1="666.86"
            x2="666.86"
            y1="176.39"
            y2="117.95"
            xlinkHref="#07332201-7176-49c2-9908-6dc4a39c4716"
          />
        </defs>
        <path
          fill="#e0e0e0"
          d="M317.5 142.55H754.52V746.3700000000001H317.5z"
          transform="rotate(-12.11 160.03 1309.797)"
        />
        <path
          fill="url(#07332201-7176-49c2-9908-6dc4a39c4716)"
          d="M324.89 152.76H747.14V736.17H324.89z"
          opacity="0.5"
          transform="rotate(-12.11 160.03 1309.797)"
        />
        <path fill="#fafafa" d="M329.81 157.1H741.31V727.62H329.81z" transform="rotate(-12.11 159.585 1307.7)" />
        <path
          fill="url(#0ee1ab3f-7ba2-4205-9d4a-9606ad702253)"
          d="M374.18 138.6H578.3199999999999V188.05H374.18z"
          transform="rotate(-12.11 100.28 1028.707)"
        />
        <path
          fill="url(#abca9755-bed1-4a97-b027-7f02ee3ffa09)"
          d="M460.93 91.9c-15.41 3.31-25.16 18.78-21.77 34.55s18.62 25.89 34 22.58 25.16-18.78 21.77-34.55-18.59-25.89-34-22.58zm9.67 45.1a16.86 16.86 0 1112.56-20 16.66 16.66 0 01-12.56 20z"
          transform="translate(-189.92 -59.59)"
        />
        <path
          fill="#6c63ff"
          d="M375.66 136.55H575.5V183.82000000000002H375.66z"
          transform="rotate(-12.11 99.61 1025.585)"
        />
        <path
          fill="#6c63ff"
          d="M460.93 91.9a27.93 27.93 0 1033.17 21.45 27.93 27.93 0 00-33.17-21.45zm9.24 43.1a16.12 16.12 0 1112.38-19.14A16.12 16.12 0 01470.17 135z"
          transform="translate(-189.92 -59.59)"
        />
        <path fill="#e0e0e0" d="M257.89 116.91H694.91V720.73H257.89z" />
        <path
          fill="url(#2632d424-e666-4ee4-9508-a494957e14ab)"
          d="M265.28 127.12H687.53V710.53H265.28z"
          opacity="0.5"
        />
        <path fill="#fff" d="M270.65 131.42H682.15V701.9399999999999H270.65z" />
        <path fill="url(#97571ef7-1c83-4e06-b701-c2e47e77dca3)" d="M374.87 106.68H579.01V156.13H374.87z" />
        <path
          fill="url(#7d32e13e-a0c7-49c4-af0e-066a2f8cb76e)"
          d="M666.86 118c-15.76 0-28.54 13.08-28.54 29.22s12.78 29.22 28.54 29.22 28.54-13.08 28.54-29.22S682.62 118 666.86 118zm0 46.08a16.86 16.86 0 1116.46-16.86A16.66 16.66 0 01666.86 164z"
          transform="translate(-189.92 -59.59)"
        />
        <path fill="#6c63ff" d="M377.02 104.56H576.86V151.83H377.02z" />
        <path
          fill="#6c63ff"
          d="M666.86 118a27.93 27.93 0 1027.93 27.93A27.93 27.93 0 00666.86 118zm0 44.05A16.12 16.12 0 11683 145.89 16.12 16.12 0 01666.86 162z"
          transform="translate(-189.92 -59.59)"
        />
        <g fill="#47e6b1" opacity="0.5">
          <path d="M15.27 737.05H19.03V758.38H15.27z" />
          <path d="M205.19 796.65H208.95V817.98H205.19z" transform="rotate(90 141.91 682.56)" />
        </g>
        <g fill="#47e6b1" opacity="0.5">
          <path d="M451.49 0H455.25V21.33H451.49z" />
          <path d="M641.4 59.59H645.16V80.92H641.4z" transform="rotate(90 578.125 -54.495)" />
        </g>
        <path
          fill="#4d8af0"
          d="M961 832.15a4.61 4.61 0 01-2.57-5.57 2.22 2.22 0 00.1-.51 2.31 2.31 0 00-4.15-1.53 2.22 2.22 0 00-.26.45 4.61 4.61 0 01-5.57 2.57 2.22 2.22 0 00-.51-.1 2.31 2.31 0 00-1.53 4.15 2.22 2.22 0 00.45.26 4.61 4.61 0 012.57 5.57 2.22 2.22 0 00-.1.51 2.31 2.31 0 004.15 1.53 2.22 2.22 0 00.26-.45 4.61 4.61 0 015.57-2.57 2.22 2.22 0 00.51.1 2.31 2.31 0 001.53-4.15 2.22 2.22 0 00-.45-.26z"
          opacity="0.5"
          transform="translate(-189.92 -59.59)"
        />
        <path
          fill="#fdd835"
          d="M326.59 627.09a4.61 4.61 0 01-2.57-5.57 2.22 2.22 0 00.1-.51 2.31 2.31 0 00-4.15-1.53 2.22 2.22 0 00-.26.45 4.61 4.61 0 01-5.57 2.57 2.22 2.22 0 00-.51-.1 2.31 2.31 0 00-1.53 4.15 2.22 2.22 0 00.45.26 4.61 4.61 0 012.57 5.57 2.22 2.22 0 00-.1.51 2.31 2.31 0 004.15 1.53 2.22 2.22 0 00.26-.45 4.61 4.61 0 015.57-2.57 2.22 2.22 0 00.51.1 2.31 2.31 0 001.53-4.15 2.22 2.22 0 00-.45-.26zM855 127.77a4.61 4.61 0 01-2.57-5.57 2.22 2.22 0 00.1-.51 2.31 2.31 0 00-4.15-1.53 2.22 2.22 0 00-.26.45 4.61 4.61 0 01-5.57 2.57 2.22 2.22 0 00-.51-.1 2.31 2.31 0 00-1.53 4.15 2.22 2.22 0 00.45.26 4.61 4.61 0 012.57 5.57 2.22 2.22 0 00-.1.51 2.31 2.31 0 004.15 1.53 2.22 2.22 0 00.26-.45 4.61 4.61 0 015.57-2.57 2.22 2.22 0 00.51.1 2.31 2.31 0 001.53-4.15 2.22 2.22 0 00-.45-.26z"
          opacity="0.5"
          transform="translate(-189.92 -59.59)"
        />
        <circle cx="812.64" cy="314.47" r="7.53" fill="#f55f44" opacity="0.5" />
        <circle cx="230.73" cy="746.65" r="7.53" fill="#f55f44" opacity="0.5" />
        <circle cx="735.31" cy="477.23" r="7.53" fill="#f55f44" opacity="0.5" />
        <circle cx="87.14" cy="96.35" r="7.53" fill="#4d8af0" opacity="0.5" />
        <circle cx="7.53" cy="301.76" r="7.53" fill="#47e6b1" opacity="0.5" />
      </svg>
    </div>
  )
}

export const EmptyIllustrationAlternate: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <div {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 997.861 450.808">
        <path fill="#f2f2f2" d="M871.992 181.558H902.151V285.956H871.992z" />
        <path
          fill="#f2f2f2"
          d="M922.068 266.317L848.715 179.052 701.475 180.398 612.156 267.396 613.961 268.556 613.316 268.556 613.316 449.513 921.871 449.513 921.871 268.556 922.068 266.317z"
        />
        <path
          fill="#e6e6e6"
          d="M848.792 179.238L757.154 286.674 757.154 449.513 921.871 449.513 921.871 266.236 848.792 179.238z"
        />
        <path fill="#3f3d56" d="M823.272 359.461H856.9110000000001V389.194H823.272z" />
        <path fill="#3f3d56" d="M823.272 307.996H856.9110000000001V337.258H823.272z" />
        <path fill="#fff" d="M823.272 359.461H856.9110000000001V389.194H823.272z" />
        <path fill="#fff" d="M823.272 307.996H856.9110000000001V337.258H823.272z" />
        <path fill="#fff" d="M673.777 351.571H707.416V381.30400000000003H673.777z" />
        <path fill="#fff" d="M673.777 300.106H707.416V329.368H673.777z" />
        <path fill="#f2f2f2" d="M633.992 181.558H664.151V285.956H633.992z" />
        <path
          fill="#f2f2f2"
          d="M684.068 266.317L610.715 179.052 463.475 180.398 374.156 267.396 375.961 268.556 375.316 268.556 375.316 449.513 683.871 449.513 683.871 268.556 684.068 266.317z"
        />
        <path
          fill="#e6e6e6"
          d="M610.792 179.238L519.154 286.674 519.154 449.513 683.871 449.513 683.871 266.236 610.792 179.238z"
        />
        <path fill="#3f3d56" d="M585.272 359.461H618.9110000000001V389.194H585.272z" />
        <path fill="#3f3d56" d="M585.272 307.996H618.9110000000001V337.258H585.272z" />
        <path fill="#fff" d="M585.272 359.461H618.9110000000001V389.194H585.272z" />
        <path fill="#fff" d="M585.272 307.996H618.9110000000001V337.258H585.272z" />
        <path fill="#fff" d="M435.777 351.571H469.416V381.30400000000003H435.777z" />
        <path fill="#fff" d="M435.777 300.106H469.416V329.368H435.777z" />
        <path fill="#f2f2f2" d="M380.154 91.46H420.454V230.961H380.154z" />
        <path
          fill="#f2f2f2"
          d="M447.068 204.718L349.051 88.112 152.302 89.91 32.951 206.161 35.362 207.711 34.501 207.711 34.501 449.513 446.804 449.513 446.804 207.711 447.068 204.718z"
        />
        <path
          fill="#e6e6e6"
          d="M349.153 88.36L226.702 231.921 226.702 449.513 446.804 449.513 446.804 204.611 349.153 88.36z"
        />
        <path fill="#3f3d56" d="M315.053 329.181H360.003V368.912H315.053z" />
        <path fill="#3f3d56" d="M315.053 260.412H360.003V299.513H315.053z" />
        <path fill="#fff" d="M315.053 329.181H360.003V368.912H315.053z" />
        <path fill="#fff" d="M315.053 260.412H360.003V299.513H315.053z" />
        <path fill="#fff" d="M115.29 318.639H160.24V358.37H115.29z" />
        <path fill="#fff" d="M115.29 249.869H160.24V288.97H115.29z" />
        <path fill="#3f3d56" d="M0 448.62H963.951V450.62H0z" />
        <ellipse cx="151.872" cy="352.472" fill="#3f3d56" rx="29.099" ry="59.374" />
        <path
          fill="#86cec1"
          d="M255.629 674.254c-11.655-69.925-.118-139.598 0-140.293l2.267.384c-.117.692-11.588 69.999 0 139.532z"
          transform="translate(-101.07 -224.596)"
        />
        <path
          fill="#86cec1"
          d="M251.026 571.202H280.867V573.502H251.026z"
          transform="rotate(-28.142 -232.632 661.676)"
        />
        <path fill="#86cec1" d="M237.023 564.485H239.323V594.327H237.023z" transform="rotate(-61.842 .158 551.475)" />
        <ellipse cx="81.955" cy="260.903" fill="#86cec1" rx="56.915" ry="116.129" />
        <path
          fill="#3f3d56"
          d="M189.364 675.404c-22.765-136.585-.23-272.673 0-274.032l2.268.384c-.23 1.356-22.699 137.077 0 273.27z"
          transform="translate(-101.07 -224.596)"
        />
        <path
          fill="#3f3d56"
          d="M179.276 475.125H237.644V477.425H179.276z"
          transform="rotate(-28.142 -290.118 565.599)"
        />
        <path
          fill="#3f3d56"
          d="M152.989 460.889H155.28900000000002V519.2570000000001H152.989z"
          transform="rotate(-61.843 -83.874 462.14)"
        />
        <ellipse cx="216.754" cy="191.008" fill="#86cec1" rx="77.883" ry="158.914" />
        <path
          fill="#3f3d56"
          d="M326.916 675.404c-31.14-186.837-.314-372.992 0-374.85l2.268.384c-.315 1.854-31.074 187.643 0 374.089z"
          transform="translate(-101.07 -224.596)"
        />
        <path
          fill="#3f3d56"
          d="M312.694 401.831H392.565V404.13100000000003H312.694z"
          transform="rotate(-28.142 -145.948 492.305)"
        />
        <path
          fill="#3f3d56"
          d="M277.146 381.926H279.446V461.79699999999997H277.146z"
          transform="rotate(-61.843 40.286 393.929)"
        />
        <ellipse cx="871.029" cy="352.472" fill="#3f3d56" rx="29.099" ry="59.374" />
        <path
          fill="#86cec1"
          d="M969.412 674.254c11.654-69.925.117-139.598-.001-140.293l-2.267.384c.117.692 11.588 69.999 0 139.532z"
          transform="translate(-101.07 -224.596)"
        />
        <path
          fill="#86cec1"
          d="M957.944 557.431H960.2439999999999V587.272H957.944z"
          transform="rotate(-61.858 721.14 544.394)"
        />
        <path
          fill="#86cec1"
          d="M971.946 578.256H1001.788V580.5559999999999H971.946z"
          transform="rotate(-28.158 488.56 668.608)"
        />
        <ellipse cx="940.946" cy="260.903" fill="#86cec1" rx="56.915" ry="116.129" />
        <path
          fill="#3f3d56"
          d="M1035.676 675.404c22.765-136.585.23-272.673 0-274.032l-2.267.384c.23 1.356 22.698 137.077 0 273.27z"
          transform="translate(-101.07 -224.596)"
        />
        <path
          fill="#3f3d56"
          d="M1015.43 447.091H1017.7299999999999V505.459H1015.43z"
          transform="rotate(-61.858 778.626 448.317)"
        />
        <path
          fill="#3f3d56"
          d="M1041.717 488.923H1100.085V491.223H1041.717z"
          transform="rotate(-28.157 572.585 579.279)"
        />
        <ellipse cx="806.148" cy="191.008" fill="#86cec1" rx="77.883" ry="158.914" />
        <path
          fill="#3f3d56"
          d="M898.124 675.404c31.14-186.837.315-372.992 0-374.85l-2.267.384c.314 1.854 31.073 187.643 0 374.089z"
          transform="translate(-101.07 -224.596)"
        />
        <path
          fill="#3f3d56"
          d="M871.261 363.045H873.5609999999999V442.916H871.261z"
          transform="rotate(-61.858 634.456 375.023)"
        />
        <path fill="#3f3d56" d="M906.809 420.712H986.68V423.012H906.809z" transform="rotate(-28.157 448.417 511.073)" />
        <path
          fill="#3f3d56"
          d="M690.674 326.062l9.205-7.363c-7.151-.789-10.09 3.111-11.292 6.198-5.587-2.32-11.67.72-11.67.72l18.42 6.688a13.938 13.938 0 00-4.663-6.243zM465.674 261.062l9.205-7.363c-7.151-.789-10.09 3.111-11.292 6.198-5.587-2.32-11.67.72-11.67.72l18.42 6.688a13.938 13.938 0 00-4.663-6.243zM832.674 232.062l9.205-7.363c-7.151-.789-10.09 3.111-11.292 6.198-5.587-2.32-11.67.72-11.67.72l18.42 6.688a13.938 13.938 0 00-4.663-6.243zM851.26 661.648a13.918 13.918 0 00-6.97 1.87 14.982 14.982 0 00-25.03 11.13h45.95a13.99 13.99 0 00-13.95-13zM384.26 661.648a13.918 13.918 0 00-6.97 1.87 14.982 14.982 0 00-25.03 11.13h45.95a13.99 13.99 0 00-13.95-13zM623.26 661.648a13.918 13.918 0 00-6.97 1.87 14.982 14.982 0 00-25.03 11.13h45.95a13.99 13.99 0 00-13.95-13z"
          transform="translate(-101.07 -224.596)"
        />
        <path
          fill="#3f3d56"
          d="M471.759 404.228L339.191 404.228 339.191 408.504 359.866 408.504 359.866 449.13 364.142 449.13 364.142 408.504 444.669 408.504 444.669 449.13 448.946 449.13 448.946 408.504 471.759 408.504 471.759 404.228z"
        />
        <path fill="#3f3d56" d="M339.452 391.434H472.02V395.71000000000004H339.452z" />
        <path fill="#3f3d56" d="M339.452 380.743H472.02V385.019H339.452z" />
        <path fill="#3f3d56" d="M339.452 370.052H472.02V374.32800000000003H339.452z" />
        <path
          fill="#3f3d56"
          d="M678.759 404.228L546.191 404.228 546.191 408.504 566.866 408.504 566.866 449.13 571.142 449.13 571.142 408.504 651.669 408.504 651.669 449.13 655.946 449.13 655.946 408.504 678.759 408.504 678.759 404.228z"
        />
        <path fill="#3f3d56" d="M546.452 391.434H679.02V395.71000000000004H546.452z" />
        <path fill="#3f3d56" d="M546.452 380.743H679.02V385.019H546.452z" />
        <path fill="#3f3d56" d="M546.452 370.052H679.02V374.32800000000003H546.452z" />
      </svg>
    </div>
  )
}
