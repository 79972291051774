import React, {useState, useEffect, HTMLAttributes} from 'react'
import Auth, {CognitoHostedUIIdentityProvider, CognitoUser} from '@aws-amplify/auth'
import {Hub} from '@aws-amplify/core'
import GoogleButton from 'react-google-button'
import {LogoMark} from './logo'
import Spinner from './spinner'
import {Banner, Button} from './tailwind'
import {AppContext, AppContextProps} from '../context'
import {useStaticQuery, graphql} from 'gatsby'
import Img, {GatsbyImageFluidProps} from 'gatsby-image'
import SEOData from './seo'
import {Role, User} from '../models'

const handleGoogleAuth = async (): Promise<void> => {
  await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google})
}

interface ImageData {
  file: {
    childImageSharp: GatsbyImageFluidProps
  }
}

const SignIn: React.FC<HTMLAttributes<HTMLElement>> = ({...props}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [authResponse, setAuthResponse] = useState<CognitoUser>()
  const [newPasswordNeeded, setNewPasswordNeeded] = useState(false)
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [busy, setBusy] = useState(false)
  const [subdomain, setSubdomain] = useState('')
  const [showTenantLogo, setShowTenantLogo] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')

  const googleAuthEnabled = subdomain === 'idspringfield'

  useEffect(() => {
    setSubdomain(window.location.hostname.split('.')[0])
    setShowTenantLogo(true)
  }, [])

  const background = useStaticQuery<ImageData>(
    graphql`
      query {
        file(relativePath: {eq: "background.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    setError('')
    setBusy(true)

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const response = await Auth.signIn({
        username: email,
        password,
      })

      setAuthResponse(response)

      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setNewPasswordNeeded(true)
      }
    } catch (error_) {
      setError(error_.message)
    } finally {
      setBusy(false)
    }
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target?.value)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target?.value)
  }

  const handleVerificationCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setVerificationCode(event.target?.value)
  }

  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewPassword(event.target?.value)
  }

  const handleNewPasswordConfirmationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewPasswordConfirmation(event.target?.value)
  }

  const handleNewPasswordSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    setError('')

    if (newPassword !== newPasswordConfirmation) {
      setError('Passwords do not match')
      return
    }

    setBusy(true)

    try {
      await Auth.completeNewPassword(authResponse, newPassword)
    } catch (error_) {
      setError(error_.message)
    } finally {
      setBusy(false)
    }
  }

  const handleForgotPasswordClicked = () => {
    setForgotPassword(true)
  }

  const handleSendCodeClick = async () => {
    setError('')
    setSuccess('')

    setBusy(true)

    try {
      await Auth.forgotPassword(email)
      setSuccess('Check your email for the verification code')
    } catch (error_) {
      setError(error_.message)
    } finally {
      setBusy(false)
    }
  }

  const handleForgotPasswordSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()
    setError('')
    setSuccess('')

    if (!email) {
      setError('Email is required')
      return
    }

    if (!newPassword) {
      setError('New password is required')
      return
    }

    if (!verificationCode) {
      setError('Verification code is required')
      return
    }

    setBusy(true)

    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword)

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const response = await Auth.signIn({
        username: email,
        password: newPassword,
      })

      setAuthResponse(response)
    } catch (error_) {
      setError(error_.message)
    } finally {
      setBusy(false)
    }
  }

  const handleImageNotPresent = () => {
    setShowTenantLogo(false)
  }

  const title = newPasswordNeeded
    ? 'Please reset your password'
    : forgotPassword
    ? 'Reset your password'
    : 'Sign in to your account'

  return (
    <div {...props} className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <div className="flex items-center justify-between">
              <LogoMark className="h-16 w-auto" />
              {showTenantLogo && (
                <img className="h-20 w-auto" src={`/media/tenants/${subdomain}`} onError={handleImageNotPresent} />
              )}
            </div>
            <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">{title}</h2>
            {/* <p className="mt-2 text-sm leading-5 text-gray-600 max-w">
              Or{' '}
              <a
                href="#"
                className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
              >
                start your 14-day free trial
              </a>
            </p> */}
          </div>

          {newPasswordNeeded ? (
            <div className="mt-8">
              <form className="space-y-6" onSubmit={handleNewPasswordSubmit}>
                <div>
                  <label htmlFor="newPassword" className="block text-sm font-medium leading-5 text-gray-700">
                    New Password
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      required
                      id="newPassword"
                      type="password"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      value={newPassword}
                      onChange={handleNewPasswordChange}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="newPasswordConfirmation"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Confirm New Password
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      required
                      id="newPasswordConfirmation"
                      type="password"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      value={newPasswordConfirmation}
                      onChange={handleNewPasswordConfirmationChange}
                    />
                  </div>
                </div>

                <div>
                  <Button disabled={busy} busy={busy} type="submit">
                    Change your password
                  </Button>
                </div>

                {error && <Banner type="error" title="Password change failed" content={error} />}
              </form>
            </div>
          ) : forgotPassword ? (
            <div className="mt-8">
              <form className="space-y-4" onSubmit={handleForgotPasswordSubmit}>
                <div>
                  <label htmlFor="forgot" className="block text-sm font-medium leading-5 text-gray-700">
                    Email address
                  </label>
                  <div className="mt-1 rounded-md shadow-sm flex space-x-2">
                    <input
                      required
                      id="forgot"
                      type="email"
                      data-test="forgot"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      value={email}
                      onChange={handleEmailChange}
                    />

                    <Button disabled={busy} busy={busy} className="w-auto flex-shrink-0" onClick={handleSendCodeClick}>
                      Send code
                    </Button>
                  </div>
                </div>

                <div>
                  <label htmlFor="verification" className="block text-sm font-medium leading-5 text-gray-700">
                    Verification code
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      required
                      id="verification"
                      data-test="verification"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      value={verificationCode}
                      onChange={handleVerificationCodeChange}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="newPassword" className="block text-sm font-medium leading-5 text-gray-700">
                    New Password
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      required
                      id="newPassword"
                      type="password"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      value={newPassword}
                      onChange={handleNewPasswordChange}
                    />
                  </div>
                </div>

                <div>
                  <Button disabled={busy} busy={busy} type="submit">
                    Reset password
                  </Button>
                </div>

                {error && <Banner type="error" title="Password reset failed" content={error} />}
                {success && <Banner type="success" title="Verification code sent" content={success} />}

                <div className="flex items-center justify-end text-sm leading-5">
                  <a
                    className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    onClick={() => setForgotPassword(false)}
                  >
                    Back to sign in
                  </a>
                </div>
              </form>
            </div>
          ) : (
            <div className="mt-8">
              {googleAuthEnabled && (
                <div>
                  <GoogleButton style={{width: 'auto'}} className="w-auto" onClick={handleGoogleAuth} />

                  <div className="mt-6 relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center text-sm leading-5">
                      <span className="px-2 bg-white text-gray-500">Or continue with</span>
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-6">
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        required
                        id="email"
                        type="email"
                        data-test="email"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                      Password
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        required
                        id="password"
                        type="password"
                        data-test="password"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                    </div>
                  </div>

                  <div>
                    <Button data-test="signin-button" disabled={busy} busy={busy} type="submit">
                      Sign in
                    </Button>
                  </div>

                  <div className="flex items-center justify-end text-sm leading-5">
                    <a
                      className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                      onClick={handleForgotPasswordClicked}
                    >
                      Forgot your password?
                    </a>
                  </div>

                  {error && <Banner data-test="error" type="error" title="Sign in failed" content={error} />}
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <Img
          className="absolute inset-0 h-full w-full max-h-screen object-cover"
          fluid={background.file.childImageSharp.fluid}
          alt=""
        />
      </div>
    </div>
  )
}

const getUser = async (): Promise<User> => {
  try {
    const user = (await Auth.currentAuthenticatedUser()) as CognitoUser
    const {payload} = user.getSignInUserSession().getIdToken()

    return {
      id: payload['custom:user'] as string,
      name: (payload['custom:name'] as string) || (payload.name as string) || (payload.email as string),
      picture: (payload['custom:picture'] as string) || (payload.picture as string),
      roles: payload['cognito:groups'] as Role[],
    }
  } catch {
    return undefined
  }
}

const AuthWrapper: React.FC = ({children}) => {
  const [initialized, setInitialized] = useState(false)
  const [context, setContext] = useState<AppContextProps>({})

  const checkUser = async (): Promise<void> => {
    const user = await getUser()

    setContext((ctx) => {
      return {
        ...ctx,
        user,
      }
    })
  }

  useEffect(() => {
    setContext((ctx) => {
      return {
        ...ctx,
        setContext,
      }
    })

    const listener = Hub.listen('auth', ({payload: {event, data}}) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          checkUser()
          break
        case 'signOut':
          setContext((ctx) => {
            return {
              ...ctx,
              user: undefined,
            }
          })
          break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data)
          break
        default:
          break
      }
    })

    const asyncAuth = async (): Promise<void> => {
      // const started = Date.now()
      await checkUser()
      // const elapsed = Date.now() - started
      // const remaining = 500 - elapsed
      // if (remaining > 0) await delay(remaining)

      setInitialized(true)
    }

    asyncAuth()

    return (): void => Hub.remove('auth', listener)
  }, [])

  return (
    <AppContext.Provider value={context}>
      <>
        {!context.user && <SEOData title="Sign in" />}
        {context.user ? (
          <div data-test="body">{children}</div>
        ) : initialized ? (
          <SignIn data-test="signin" />
        ) : (
          <div className="flex h-screen">
            <div className="m-auto">
              <Spinner className="h-24 w-24" />
            </div>
          </div>
        )}
      </>
    </AppContext.Provider>
  )
}

export default AuthWrapper
