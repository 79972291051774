import {gql} from '@apollo/client'

export const APPOINTMENT_SELECT_FRAGMENT = gql`
  fragment AppointmentSelectFragment on Appointment {
    tid
    id
    parentId

    events {
      id
      parentId

      patient
      locationId
      requestingId
      scheduled
      length
      important
      notes
      status
      providerId
      roomId
      createdAt
      tagIds

      requesting {
        id
        name
      }
      provider {
        id
        name
        roles
      }
      tags {
        id
        name
        color
        level
      }
      room {
        id
        name
      }
    }
  }
`

export const APPOINTMENT_EVENT_SELECT_FRAGMENT = gql`
  fragment AppointmentEventSelectFragment on AppointmentEvent {
    tid
    id
    appointmentId
    parentId

    patient
    locationId
    requestingId
    scheduled
    length
    important
    notes
    status
    providerId
    roomId
    tagIds

    createdAt
    requesting {
      id
      name
    }
    provider {
      id
      name
      roles
    }
    room {
      id
      name
    }
    tags {
      id
      name
      color
      level
    }
  }
`
