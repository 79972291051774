import {gql, useMutation, useQuery} from '@apollo/client'
import React, {useState} from 'react'
import {
  Table,
  PageHeading,
  SectionHeading,
  ConfirmDialog,
  Banner,
  Button,
  ModalProps,
  Modal,
  FormInput,
} from '../tailwind'
import {RouteComponentProps} from '@reach/router'
import SEOData from '../seo'
import {Skeleton} from '../skeleton'
import {LocationGetLocationQuery} from '../../graphql/LocationGetLocationQuery'
import {DropResult} from 'react-beautiful-dnd'
import {Room} from '../../models'
import {PlusCircleIconSmall} from '../icon'

export const GET_LOCATION = gql`
  query LocationGetLocationQuery($id: ID!) {
    location(id: $id) {
      id
      name
      address

      rooms {
        id
        name
        locationId
        order
      }
    }
  }
`

export const UPDATE_LOCATION = gql`
  mutation LocationUpdateLocationMutation($id: ID!, $name: String!) {
    updateLocation(id: $id, name: $name) {
      id
      name
    }
  }
`

export const ADD_ROOM = gql`
  mutation LocationAddRoomMutation($locationId: ID!, $name: String!) {
    addRoom(locationId: $locationId, name: $name) {
      id
      name
    }
  }
`

export const DELETE_ROOM = gql`
  mutation LocationDeleteRoomMutation($id: ID!) {
    deleteRoom(id: $id)
  }
`

export const UPDATE_ROOM = gql`
  mutation LocationUpdateRoomMutation($id: ID!, $name: String!, $order: Int!) {
    updateRoom(id: $id, name: $name, order: $order) {
      id
      name
      order
    }
  }
`

interface RoomEditorProps extends ModalProps {
  locationId: string
  room?: Room
}

const RoomEditor: React.FC<RoomEditorProps> = ({locationId, room, onDismiss}) => {
  const [name, setName] = useState<string>(room?.name ?? '')
  const [nameError, setNameError] = useState<string>()
  const [addRoom, {loading: adding, error: addError}] = useMutation(ADD_ROOM)
  const [updateRoom, {loading: updating, error: updateError}] = useMutation(UPDATE_ROOM)
  const error = addError || updateError
  const busy = adding || updating

  const handleSave = async (e: React.FormEvent | React.MouseEvent) => {
    e.preventDefault()

    setNameError(undefined)

    if (!name) {
      setNameError('Name is required')
      return
    }

    if (room) {
      await updateRoom({variables: {id: room.id, name, order: room.order}})
    } else {
      await addRoom({variables: {name, locationId}})
    }

    onDismiss()
  }

  const handleNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  return (
    <Modal onDismiss={onDismiss}>
      <SectionHeading title={`${room ? 'Edit' : 'Add'} Room`} />
      <form autoComplete="off" className="mt-3" onSubmit={handleSave}>
        <FormInput required autoFocus label="Room Name" value={name} error={nameError} onChange={handleNameChanged} />
        <div className="mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <Button fullWidth className="sm:col-start-2" busy={busy} disabled={busy} type="submit" onClick={handleSave}>
            Save
          </Button>
          <Button fullWidth secondary className="mt-3 sm:mt-0 sm:col-start-1" onClick={onDismiss}>
            Cancel
          </Button>
        </div>

        {error && <Banner type="error" content={error.message} className="mt-3" />}
      </form>
    </Modal>
  )
}

export interface LocationPageProps extends RouteComponentProps {
  locationId?: string
}

const LocationPage: React.FC<LocationPageProps> = ({locationId}) => {
  const {data, loading, refetch} = useQuery<LocationGetLocationQuery>(GET_LOCATION, {
    variables: {id: locationId},
  })

  const [updateLocation] = useMutation(UPDATE_LOCATION)
  const [deleteRoomMutation] = useMutation(DELETE_ROOM)
  const [updateRoom] = useMutation(UPDATE_ROOM)

  const {location} = data ?? {}
  const [rooms, setRooms] = useState<Room[]>()
  const [editRoom, setEditRoom] = useState<Room>()

  const [deleteRoom, setDeleteRoom] = useState<Room>()
  const [showAddRoom, setShowAddRoom] = useState(false)

  const handleDeleteRoom = (room: Room) => {
    setDeleteRoom(room)
  }

  const handleDeleteConfirmation = async (result: boolean) => {
    setDeleteRoom(undefined)

    if (result) {
      await deleteRoomMutation({variables: {id: deleteRoom.id}})
      refetch()
    }
  }

  const handleAddRoom = () => {
    setShowAddRoom(true)
  }

  const handleAddRoomDismissed = () => {
    refetch()
    setShowAddRoom(false)
  }

  const handleEditRoomDismissed = () => {
    setEditRoom(undefined)
  }

  const handleEditRoom = (room: Room) => {
    setEditRoom(room)
  }

  const handleNameEdited = (value: string) => {
    updateLocation({variables: {id: location?.id, name: value}})
  }

  const sortedRooms = (rooms ?? (location?.rooms as Room[]))?.slice().sort((a, b) => a.order - b.order)

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return

    const {index: sourceIndex} = result.source
    const {index: destinationIndex} = result.destination

    const rooms = sortedRooms.slice()
    rooms.splice(destinationIndex, 0, rooms.splice(sourceIndex, 1)[0])

    const reordered: Room[] = []
    const updateTasks = []
    for (const [index, room] of rooms.entries()) {
      const updated = {
        ...room,
        order: index,
      }
      reordered.push(updated)

      const original = rooms.find((room) => room.id === updated.id)
      if (original.order !== updated.order) {
        updateTasks.push({id: updated.id, name: updated.name, order: updated.order})
      }
    }

    setRooms(reordered)

    for (const task of updateTasks) {
      // eslint-disable-next-line no-await-in-loop
      await updateRoom({variables: task})
    }

    setRooms(undefined)
  }

  if (loading) return <Skeleton />

  return (
    <div>
      <SEOData title={location.name} />
      <PageHeading
        editable
        placeholder="Untitled Location"
        title={location.name}
        breadcrumbs={[
          {name: 'Home', path: '/'},
          {name: 'Locations', path: '/locations/'},
        ]}
        onEdited={handleNameEdited}
      />

      <SectionHeading title="Rooms">
        <Button size="tiny" icon={PlusCircleIconSmall} className="mt-3 md:mt-0" onClick={handleAddRoom}>
          Add Room
        </Button>
      </SectionHeading>
      <Table
        draggable
        items={sortedRooms}
        columns={[
          {name: 'Name', value: (room) => room.name},
          {
            value: (room) => (
              <div className="flex justify-end space-x-2 whitespace-nowrap text-right text-sm leading-5">
                <a className="font-medium text-indigo-600 hover:text-indigo-900" onClick={() => handleEditRoom(room)}>
                  Edit
                </a>
                <a
                  className="font-medium text-indigo-600 hover:text-indigo-900 cursor-pointer"
                  onClick={() => handleDeleteRoom(room)}
                >
                  Delete
                </a>
              </div>
            ),
          },
        ]}
        onDragEnd={handleDragEnd}
      />

      {deleteRoom && (
        <ConfirmDialog title={`Confirm Delete: ${deleteRoom.name}`} onDismiss={handleDeleteConfirmation}>
          <h1 className="text-gray-700">Are you sure you want to delete this room?</h1>
          <Banner type="error" content="This action cannot be undone" />
        </ConfirmDialog>
      )}

      {showAddRoom && <RoomEditor locationId={locationId} onDismiss={handleAddRoomDismissed} />}
      {editRoom && <RoomEditor locationId={locationId} room={editRoom} onDismiss={handleEditRoomDismissed} />}
    </div>
  )
}

export default LocationPage
