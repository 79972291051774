import React from 'react'
import {RouteComponentProps, Router as ReachRouter} from '@reach/router'
import LocationPage from './pages/location'
import UserPage from './pages/user'
import ProviderStatsPage from './stats/provider'
import TagStatsPage from './stats/tag'
import StatAppointmentsPage from './pages/stat-appointments'

const Router: React.FC = ({children}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const ServerSidePageContent = (_props: RouteComponentProps) => <div>{children}</div>

  return (
    <ReachRouter>
      <LocationPage path="/locations/:locationId" />
      <UserPage path="/team/:userId" />
      <ProviderStatsPage path="/team-stats/providers/:providerId" />
      <TagStatsPage path="/team-stats/tags/:tagId" />
      <StatAppointmentsPage path="/team-stats/:statId" referrer={{name: 'Team Stats', path: '/team-stats/'}} />
      {/* By default we serve pages from the server */}
      <ServerSidePageContent default />
    </ReachRouter>
  )
}

export default Router
