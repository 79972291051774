import {DocumentNode, useQuery} from '@apollo/client'
import React from 'react'
import {Empty, Qualifier, qualifiers, StatsSummaryProps} from '.'
import {Skeleton} from '../skeleton'
import {Breadcrumb, PageHeading, Select} from '../tailwind'
import {usePersistedState} from '../../hooks'
import {DateRange, Stat, StatQualifier, Tag, User} from '../../models'
import {CalendarIcon} from '../icon'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle'
import {toDateString} from '../../../common'

const calculatePreviousPeriod = (dateRange: DateRange): DateRange | undefined => {
  if (!dateRange) return undefined

  const {startDate, endDate} = dateRange
  if (!startDate || !endDate) return undefined

  const firstDate = new Date(startDate)
  const secondDate = new Date(endDate)

  const timeBetween = secondDate.getTime() - firstDate.getTime()
  const daysBetween = timeBetween / (1000 * 3600 * 24)

  secondDate.setUTCDate(firstDate.getUTCDate() - 1)
  firstDate.setUTCDate(firstDate.getUTCDate() - 1 - daysBetween)

  return {
    startDate: toDateString(firstDate, true),
    endDate: toDateString(secondDate, true),
  }
}

interface StatsContentProps {
  query: DocumentNode
  variables?: Record<string, unknown>
  StatSummary: React.FC<StatsSummaryProps>
  dateRange?: DateRange
  emptyError?: string
  provider?: User
  tag?: Tag
}

interface StatQuery {
  stats: Stat[]
}

const StatsContent: React.FC<StatsContentProps> = ({
  query,
  variables,
  dateRange,
  StatSummary,
  emptyError,
  provider,
  tag,
}) => {
  const prevDateRange = calculatePreviousPeriod(dateRange)

  const {data, loading} = useQuery<StatQuery>(query, {
    fetchPolicy: 'cache-and-network',
    variables: {...(variables ?? {}), dateRange},
  })
  const {data: prevData, loading: prevLoading} = useQuery<StatQuery>(query, {
    fetchPolicy: 'cache-and-network',
    variables: {...(variables ?? {}), dateRange: prevDateRange},
  })

  const stats = data?.stats ?? []
  const prevStats = (dateRange ? prevData?.stats : undefined) ?? []
  if (loading || prevLoading) return <Skeleton />

  const isEmpty = data && stats.length === 0
  if (isEmpty) return <Empty title={emptyError ?? 'No stats found'} />

  return <StatSummary stats={stats} prevStats={prevStats} provider={provider} tag={tag} dateRange={dateRange} />
}

interface StatsPageProps extends StatsContentProps {
  title: string
  breadcrumbs?: Breadcrumb[]
}

export const StatsPage: React.FC<StatsPageProps> = ({title, breadcrumbs, ...props}) => {
  const [qualifier, setQualifier] = usePersistedState<Qualifier>(
    'stat-qualifier',
    qualifiers.find((q) => q.id === StatQualifier.LAST_7_DAYS)
  )
  const isCustom = qualifier?.id === StatQualifier.CUSTOM

  const handleQualifierSelection = (item: Qualifier) => {
    setQualifier(item)
  }

  const [dates, setDates] = usePersistedState<Date[]>('stat-dates', [new Date(), new Date()])
  const convertedDates = dates ? dates.map((date) => (typeof date === 'string' ? new Date(date) : date)) : []

  const dateRange =
    convertedDates.length === 2
      ? {startDate: toDateString(convertedDates[0]), endDate: toDateString(convertedDates[1])}
      : undefined

  return (
    <>
      <PageHeading title={title} breadcrumbs={breadcrumbs}>
        <div className="mt-4 space-y-2 md:mt-0 md:flex md:items-center md:space-x-2 md:space-y-0">
          <div className="md:w-40 md:mt-0">
            <Select
              value="id"
              display="name"
              items={qualifiers}
              initial={qualifier}
              onSelection={handleQualifierSelection}
            />
          </div>
          {isCustom && (
            <DateRangePicker
              className="w-full md:w-auto text-base pl-3 py-2 text-left sm:text-sm"
              value={convertedDates}
              maxDate={new Date()}
              calendarType="US"
              calendarIcon={<CalendarIcon className="w-5 h-5 text-gray-700" />}
              // eslint-disable-next-line unicorn/no-null
              clearIcon={null}
              onChange={setDates}
            />
          )}
        </div>
      </PageHeading>
      <StatsContent {...props} dateRange={isCustom ? dateRange : qualifier.dateRange} />
    </>
  )
}
