/* eslint-disable @typescript-eslint/unbound-method */
import React, {HTMLAttributes, useState} from 'react'
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd'

import {AppointmentCard} from './appointment-card'
import {Appointment, Rule, Status} from '../../models'
import {ChevronDownIconSmall} from '../icon'
import {useWindowSize} from '../../hooks'

const getStyle = (style: DraggingStyle | NotDraggingStyle, snapshot: DraggableStateSnapshot): React.CSSProperties => {
  if (!snapshot.isDragging) return {}
  if (!snapshot.isDropAnimating) {
    return style
  }

  return {
    ...style,
    // cannot be 0, but make it super tiny
    transitionDuration: `0.001s`,
  }
}

export interface Action {
  name: string
  onSubmit: () => void
}

export interface QueueProps extends HTMLAttributes<HTMLElement> {
  status: Status
  compact?: boolean
  rules?: Rule[]
  collapsible?: boolean
  fixed?: boolean
  data: Appointment[]
  onEdit?: (appointment: Appointment) => void
  onDelete?: (appointment: Appointment) => void
  onComplete?: (appointment: Appointment) => void
  onSplit?: (appointment: Appointment) => void
  onClaimed?: (appointment: Appointment) => void
  onWaitingChange?: (appointment: Appointment) => void
  onSeated?: (appointment: Appointment) => void
  onPending?: (appointment: Appointment) => void
}

export const Queue: React.FC<QueueProps> = React.memo(
  ({
    status,
    rules,
    compact = false,
    collapsible = false,
    fixed = false,
    data,
    onEdit,
    onDelete,
    onComplete,
    onSplit,
    onClaimed,
    onWaitingChange,
    onSeated,
    onPending,
    className,
    ...props
  }) => {
    const empty = !data || data.length === 0
    const hasData = !empty
    const hasOne = data && data.length === 1

    const {width} = useWindowSize()
    const disableDragDrop = fixed || width <= 640

    const [showCount, setShowCount] = useState(5)
    const displayed = collapsible ? data.slice(0, showCount) : data
    const hasMore = collapsible && showCount < data.length

    const handleShowMoreClick = () => {
      setShowCount((count) => count + 5)
    }

    return (
      <div {...props} className={`${className} mb-10 -mx-4 sm:mx-0`}>
        <Droppable droppableId={status} isDropDisabled={disableDragDrop}>
          {(provided: DroppableProvided, snapshotDroppable: DroppableStateSnapshot): React.ReactElement => (
            <div className="relative bg-white shadow sm:rounded-md">
              {hasData && (
                <ul ref={provided.innerRef} {...provided.droppableProps}>
                  {displayed.map((record, index) => (
                    <Draggable key={record.id} draggableId={record.id} index={index} isDragDisabled={disableDragDrop}>
                      {(
                        providedDraggable: DraggableProvided,
                        snapshotDraggable: DraggableStateSnapshot
                      ): React.ReactElement => (
                        <div
                          ref={providedDraggable.innerRef}
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                          style={getStyle(providedDraggable.draggableProps.style, snapshotDraggable)}
                        >
                          <AppointmentCard
                            data-test="appointment-card"
                            appointment={record}
                            compact={compact}
                            rules={rules}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            onComplete={onComplete}
                            onSplit={onSplit}
                            onClaimed={onClaimed}
                            onWaiting={onWaitingChange}
                            onSeated={onSeated}
                            onPending={onPending}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </ul>
              )}
              {hasMore && (
                <span
                  title="Show more"
                  className="w-10 h-10 absolute z-auto bottom-0 left-1/2 transform translate-y-5 -translate-x-1/2 text-white rounded-full shadow-md bg-secondary"
                >
                  <button
                    type="button"
                    className="w-full h-full flex items-center hover:opacity-75 focus:outline-none active:outline-none"
                    onClick={handleShowMoreClick}
                  >
                    <ChevronDownIconSmall className="w-8 h-8 m-auto" />
                  </button>
                </span>
              )}
              {empty && (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <div className="h-24" />
                </div>
              )}
              <div className={(!empty && snapshotDroppable.isDraggingOver) || hasOne ? '' : 'hidden'}>
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </div>
    )
  }
)
