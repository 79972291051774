import {gql, useQuery} from '@apollo/client'
import React from 'react'
import {ProviderStatsSummary} from '../stats'
import {Skeleton} from '../skeleton'
import {User} from '../../models'
import {RouteComponentProps} from '@reach/router'
import SEOData from '../seo'
import {ProviderGetUserQuery} from '../../graphql/ProviderGetUserQuery'
import {StatsPage} from './page'

export const GET_USER = gql`
  query ProviderGetUserQuery($providerId: ID!) {
    user(id: $providerId) {
      id
      picture
      name
      roles
    }
  }
`

export const GET_STATS = gql`
  query ProviderGetProviderStatsQuery($dateRange: DateRange, $provider: ID) {
    stats(dateRange: $dateRange, provider: $provider) {
      id

      metric
      date
      updatedAt
      value

      provider {
        id
        picture
        name
      }

      tag {
        id
        name
        color
        level
      }
    }
  }
`

export interface ProviderStatsPageProps extends RouteComponentProps {
  providerId?: string
}

const ProviderStatsPage: React.FC<ProviderStatsPageProps> = ({providerId}) => {
  const {data} = useQuery<ProviderGetUserQuery>(GET_USER, {
    variables: {providerId},
  })

  const provider = data?.user as User
  if (!provider) return <Skeleton />

  const title = `${provider.name}'s Stats`

  return (
    <>
      <SEOData title={title} />
      <StatsPage
        title={title}
        breadcrumbs={[
          {name: 'Home', path: '/'},
          {name: 'Team Stats', path: '/team-stats/'},
        ]}
        query={GET_STATS}
        variables={{provider: providerId}}
        StatSummary={ProviderStatsSummary}
        emptyError="It looks like this provider doesn't have any stats"
        provider={provider}
      />
    </>
  )
}

export default ProviderStatsPage
