import React, {HTMLAttributes} from 'react'

import {PromptField} from './appointment-editor'
import {Appointment, Status, AppointmentEvent} from '../../models'
import {Button, ButtonProps} from '../tailwind'
import {EmptyIllustrationAlternate} from '../empty'

export * from './appointment-card'
export * from './appointment-editor'
export * from './appointment-importer'
export * from './queue'

export interface EmptyProps extends HTMLAttributes<HTMLElement> {
  title: string
  actions?: ButtonProps[]
}

export const Empty: React.FC<EmptyProps> = ({actions, title, ...props}) => (
  <div {...props} className="relative w-full h-full mt-8 space-y-12 grid place-items-center">
    <EmptyIllustrationAlternate className="w-4/5 max-w-xl" />
    <div className="space-y-4">
      <h1 className="text-gray-800 text-center text-2xl font-extralight">{title}</h1>
      {actions && (
        <div className="sm:grid sm:grid-cols-2 sm:justify-items-stretch sm:gap-3">
          {actions.map((action, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Button key={index} {...action} size="small" />
            )
          })}
        </div>
      )}
    </div>
  </div>
)

export interface QueueHeadingProps extends HTMLAttributes<HTMLElement> {
  actions?: ButtonProps[]
  controls?: React.ReactNode
}

export const QueueHeading: React.FC<QueueHeadingProps> = ({children, actions, controls}) => {
  return (
    <div className="pb-4 border-b border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
      <h3 className="flex items-center space-x-3 text-2xl leading-6 font-extralight text-gray-900 tracking-wider uppercase">
        {children}
      </h3>
      {actions && (
        <div className="hidden sm:flex sm:space-x-3">
          {actions.map((action, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Button key={index} {...action} size="small" />
            )
          })}
        </div>
      )}
      {controls && controls}
    </div>
  )
}

export const sortAppointments = (appointments: Appointment[]): Appointment[] =>
  appointments
    .slice()
    .sort(({lastStatusChangedAt: previous}, {lastStatusChangedAt: current}) => previous - (current ?? previous + 1))

export const filterAppointments = (appointments: Appointment[], status: Status): Appointment[] =>
  appointments?.filter((appointment) => appointment.status === status) ?? []

export const queueRequirements: Record<string, PromptField[]> = {
  [Status.SEATED]: [PromptField.Room, PromptField.Requesting],
  [Status.WAITING]: [PromptField.Room, PromptField.Requesting, PromptField.Provider, PromptField.Notes],
  [Status.CLAIMED]: [PromptField.Room, PromptField.Requesting, PromptField.Provider, PromptField.Notes],
}

// eslint-disable-next-line complexity
export const hydrateAppointment = (
  appointment: Appointment,
  events: Record<string, AppointmentEvent[]>,
  promptAppointment?: Appointment
): Appointment => {
  const allEvents = events[appointment.id] ? [...events[appointment.id], ...appointment.events] : appointment.events
  const sortedEvents: AppointmentEvent[] = allEvents
    ?.slice()
    .sort((previous: AppointmentEvent, current: AppointmentEvent) => previous.createdAt - current.createdAt)
  const reverseSortedEvents = sortedEvents?.slice().reverse()

  const statusEvents = reverseSortedEvents
    .filter((e) => e.status)
    .slice()
    .sort((previous: AppointmentEvent, current: AppointmentEvent) => previous.createdAt - current.createdAt)
    .reverse()
  const getPreviousStatusEvent = (events: AppointmentEvent[], index: number) =>
    events.length > index ? events[index + 1] : undefined
  const getNextStatusEvent = (events: AppointmentEvent[], index: number) => (index > 0 ? events[index - 1] : undefined)
  const shortClaimedEventsRemoved = statusEvents.filter((e, i) => {
    if (e.status !== Status.CLAIMED) return true

    const previousStatusEvent = getPreviousStatusEvent(statusEvents, i)
    const nextStatusEvent = getNextStatusEvent(statusEvents, i)
    if (!previousStatusEvent || !nextStatusEvent) return true

    const numberOfSeconds = nextStatusEvent.createdAt - e.createdAt
    return numberOfSeconds > 3 * 60
  })
  const filteredStatusEvents = shortClaimedEventsRemoved.filter((e, i) => {
    const previousStatusEvent = getPreviousStatusEvent(shortClaimedEventsRemoved, i)
    if (!previousStatusEvent) return true

    return previousStatusEvent.status !== e.status
  })

  const providerLastRemoved = reverseSortedEvents.find((e) => e.providerId === '')?.createdAt ?? 0
  const requestingLastRemoved = reverseSortedEvents.find((e) => e.requestingId === '')?.createdAt ?? 0
  const roomLastRemoved = reverseSortedEvents.find((e) => e.roomId === '')?.createdAt ?? 0
  const notesLastRemoved = reverseSortedEvents.find((e) => e.notes === '')?.createdAt ?? 0

  const {status, createdAt: lastStatusChangedAt} = filteredStatusEvents.find((e) => e.status) ?? {}
  const {createdAt: lastStatusBeforeCompleteChangedAt} =
    filteredStatusEvents.find((e) => e.status && e.status !== Status.COMPLETE) ?? {}
  const {scheduled} = reverseSortedEvents.find((e) => e.scheduled) ?? {}
  const {length} = reverseSortedEvents.find((e) => e.length) ?? {}
  const {patient} = reverseSortedEvents.find((e) => e.patient) ?? {}
  const {locationId} = reverseSortedEvents.find((e) => e.locationId) ?? {}
  const {parentId} = reverseSortedEvents.find((e) => e.parentId) ?? {}
  const {requestingId, requesting} =
    reverseSortedEvents.find((e) => e.requestingId && e.createdAt > requestingLastRemoved) ?? {}
  const {important} = reverseSortedEvents.find((e) => e.important !== undefined && e.important !== null) ?? {}
  const {notes} = reverseSortedEvents.find((e) => e.notes && e.createdAt > notesLastRemoved) ?? {}
  const {tagIds, tags} = reverseSortedEvents.find((e) => e.tagIds && e.tagIds.length > 0) ?? {}
  const {providerId, provider} =
    reverseSortedEvents.find((e) => e.providerId && e.createdAt > providerLastRemoved) ?? {}
  const {roomId, room} = reverseSortedEvents.find((e) => e.roomId && e.createdAt > roomLastRemoved) ?? {}

  const lastTimeInScheduledState = reverseSortedEvents?.find((e) => e.status === Status.PENDING)?.createdAt
  const firstNonPending = sortedEvents?.find(
    (e) => e.status && e.status !== Status.PENDING && e.createdAt > lastTimeInScheduledState
  )

  const start = Math.min(scheduled, firstNonPending?.createdAt ?? scheduled)
  const actualStart = firstNonPending?.createdAt

  const pendingPrompt = appointment.id === promptAppointment?.id
  const completed = status === Status.COMPLETE
  const lastDuration = completed
    ? lastStatusChangedAt - reverseSortedEvents.find((e) => e.status && e.status !== Status.COMPLETE)?.createdAt
    : undefined
  const totalDuration = completed ? lastStatusChangedAt - start : undefined
  const actualDuration = completed ? lastStatusChangedAt - actualStart : undefined

  return {
    ...appointment,
    status: pendingPrompt ? promptAppointment?.desiredStatus : status,
    lastStatusChangedAt: pendingPrompt ? undefined : lastStatusChangedAt,
    lastStatusBeforeCompleteChangedAt: pendingPrompt ? undefined : lastStatusBeforeCompleteChangedAt,
    scheduled,
    scheduledDisplay: scheduled
      ? new Date(scheduled * 1000).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
      : undefined,
    length,
    start,
    actualStart,
    patient,
    locationId,
    requestingId,
    requesting,
    important,
    notes,
    tagIds,
    tags,
    providerId,
    provider,
    roomId,
    room,
    parentId: appointment.parentId || parentId,
    pendingPrompt,
    completed,
    lastDuration,
    actualDuration,
    totalDuration,
  }
}
