/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable camelcase */
import {gql, useMutation, useQuery} from '@apollo/client'
import React, {useCallback, useEffect, useState} from 'react'
import {
  Avatar,
  Banner,
  Button,
  Checkbox,
  ConfirmDialog,
  FormInput,
  PageHeading,
  Panel,
  SectionHeading,
} from '../tailwind'
import {navigate, RouteComponentProps} from '@reach/router'
import SEOData from '../seo'
import {Skeleton} from '../skeleton'
import {UserGetUserQuery} from '../../graphql/UserGetUserQuery'
import {Role, ROLE_DESCRIPTIONS, ROLE_NAMES} from '../../models'
import {useDropzone} from 'react-dropzone'

import '../../../vendor/doka/doka.css'
import {
  // editor
  openEditor,
  locale_en_gb,
  createDefaultImageReader,
  createDefaultImageWriter,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_crop_defaults,
} from '../../../vendor/doka/doka.js'
import {UserAddMediaMutation} from '../../graphql/UserAddMediaMutation'

setPlugins(plugin_crop)

const mediaPrefix = process.env.MEDIA_PREFIX

// This function is called when the user taps the edit button, it opens the editor and returns the modified file when done
const editImage = (image: File, done: (processed: unknown) => Promise<void>) => {
  const editor = openEditor({
    src: image,
    imageState: {},
    imageReader: createDefaultImageReader(),
    imageWriter: createDefaultImageWriter(),
    imageCropAspectRatio: 1,
    ...plugin_crop_defaults,
    cropEnableSelectPreset: false,
    locale: {
      ...locale_en_gb,
      ...plugin_crop_locale_en_gb,
    },
  })

  editor.on('close', () => {
    // nothing
  })

  editor.on('process', ({dest}) => {
    done(dest)
  })
}

export const GET_USER = gql`
  query UserGetUserQuery($id: ID!) {
    user(id: $id) {
      id
      name
      picture
      email
      roles
    }
  }
`

export const UPDATE_USER = gql`
  mutation UserUpdateUserMutation($id: ID!, $name: String!, $email: String!, $roles: [Role!]!, $picture: String) {
    updateUser(id: $id, name: $name, email: $email, roles: $roles, picture: $picture) {
      id
      name
      email
      roles
      picture
    }
  }
`

export const DELETE_USER = gql`
  mutation UserDeleteUserMutation($id: ID!) {
    deleteUser(id: $id)
  }
`

export const ADD_MEDIA = gql`
  mutation UserAddMediaMutation($name: String!) {
    addMedia(name: $name) {
      name
      file {
        bucket
        key
        region
        uploadUrl
      }
    }
  }
`

export interface UserPageProps extends RouteComponentProps {
  userId?: string
}

const UserPage: React.FC<UserPageProps> = ({userId}) => {
  const {data, loading} = useQuery<UserGetUserQuery>(GET_USER, {
    variables: {id: userId},
  })
  const {user} = data ?? {}

  const [updateUser] = useMutation(UPDATE_USER)
  const [deleteUserMutation] = useMutation(DELETE_USER)
  const [addMediaMutation] = useMutation<UserAddMediaMutation>(ADD_MEDIA)
  const [updatingAvatar, setUpdatingAvatar] = useState(false)
  const [deleteUser, setDeleteUser] = useState(false)

  const handleDelete = () => {
    setDeleteUser(true)
  }

  const handleDeleteConfirmation = async (result: boolean) => {
    setDeleteUser(false)

    if (result) {
      await deleteUserMutation({variables: {id: user?.id}})
      navigate('/team/')
    }
  }

  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string>()
  const [emailSaved, setEmailSaved] = useState(false)

  const emailChanged = email !== user?.email

  const [roles, setRoles] = useState<Role[]>([])

  const hasRole = (role: Role) => roles.includes(role)

  useEffect(() => {
    if (user?.email) setEmail(user?.email)
    if (user?.roles) setRoles(user?.roles)
  }, [user])

  const {open, acceptedFiles, getInputProps} = useDropzone({accept: 'image/*', maxFiles: 1})

  const uploadFile = useCallback(async (url: string, file: File) => {
    await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {'Content-Type': ''},
    })
  }, [])

  const updatePicture = useCallback(
    async (picture: string) => {
      await updateUser({
        variables: {
          id: user?.id,
          name: user?.name,
          email: user?.email,
          roles: user?.roles,
          picture,
        },
      })
    },
    [updateUser, user?.email, user?.id, user?.name, user?.roles]
  )

  useEffect(() => {
    const [newAvatar] = acceptedFiles
    if (newAvatar) {
      setUpdatingAvatar(true)
      editImage(newAvatar, async (output: File) => {
        const result = await addMediaMutation({variables: {name: output.name}})
        await uploadFile(result.data.addMedia.file.uploadUrl, output)
        await updatePicture(`${mediaPrefix}${result.data.addMedia.file.key}`)

        setUpdatingAvatar(false)
      })
    }
  }, [acceptedFiles, addMediaMutation, updatePicture, uploadFile])

  const handleNameEdited = (value: string) => {
    updateUser({variables: {id: user?.id, name: value, email: user?.email, roles: user?.roles, picture: user?.picture}})
  }

  const validateEmail = (email: string): boolean => {
    setEmailSaved(false)

    if (/^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z-]+(?:\.[\dA-Za-z-]+)*$/.test(email)) {
      setEmailError(undefined)
      return true
    }

    setEmailError('Not a valid email address')
    return false
  }

  const handleEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    validateEmail(e.target.value)
  }

  const handleEmailSaved = async (e: React.FormEvent | React.MouseEvent) => {
    e.preventDefault()

    setEmailSaved(false)
    if (!validateEmail(email)) return

    await updateUser({variables: {id: user?.id, name: user?.name, email, roles: user?.roles, picture: user?.picture}})
    setEmailSaved(true)
  }

  const handleAvatarChangeClick = () => {
    open()
  }

  const handleRoleToggled = (value: boolean, role: Role) => {
    setRoles((roles) => {
      let newRoles
      const otherRoles = roles.filter((r) => r !== role)
      if (value) {
        newRoles = [...otherRoles, role]
      } else {
        newRoles = otherRoles
      }

      updateUser({
        variables: {id: user?.id, name: user?.name, email: user?.email, roles: newRoles, picture: user?.picture},
      })
      return newRoles
    })
  }

  if (loading) return <Skeleton />

  const possibleRoles = [Role.TEAM, Role.DOCTOR, Role.HYGIENE, Role.TREATMENT, Role.ASSISTANT, Role.OFFICE, Role.ADMIN]

  return (
    <div>
      <SEOData title={user.name} />
      <PageHeading
        editable
        placeholder="User 1"
        title={user.name}
        breadcrumbs={[
          {name: 'Home', path: '/'},
          {name: 'Team', path: '/team/'},
        ]}
        onEdited={handleNameEdited}
      />

      <div className="space-y-5">
        <Panel>
          <SectionHeading title="Profile" description="Change the user's basic profile information." divider={false} />
          <form className="flex flex-col space-y-3" onSubmit={handleEmailSaved}>
            <div className="sm:flex sm:items-end sm:space-x-3 sm:space-y-0">
              <FormInput
                required
                className="w-full sm:max-w-xs"
                label="Email"
                value={email}
                error={emailError}
                onChange={handleEmailChanged}
              />
              <Button disabled={Boolean(emailError) || !emailChanged} size="small" onClick={handleEmailSaved}>
                Save
              </Button>
              {emailSaved && <p className="self-center text-green-700">Email updated</p>}
            </div>
            <div className="col-span-3">
              <input {...getInputProps()} />
              <label className="text-sm font-medium text-gray-700">Photo</label>
              <div className="mt-1 flex items-center">
                <Avatar user={user} size="large" />
                <Button
                  secondary
                  busy={updatingAvatar}
                  disabled={updatingAvatar}
                  size="small"
                  className="ml-5"
                  onClick={handleAvatarChangeClick}
                >
                  Change
                </Button>
              </div>
            </div>
          </form>
        </Panel>

        <Panel>
          <SectionHeading
            title="Roles"
            description="Change the user's roles to control their permissions in the app."
            divider={false}
          />

          <fieldset>
            <legend className="sr-only">Roles</legend>
            <div className="space-y-3">
              {possibleRoles.map((role) => (
                <Checkbox
                  key={role}
                  id={`${role}Role`}
                  checked={hasRole(role)}
                  description={ROLE_DESCRIPTIONS[role]}
                  onToggle={(v) => handleRoleToggled(v, role)}
                >
                  {ROLE_NAMES[role]}
                </Checkbox>
              ))}
            </div>
          </fieldset>
        </Panel>

        <Panel>
          <SectionHeading
            title="Delete user"
            description="Once you delete this user, your organization will lose all data associated with it."
            divider={false}
          />
          <div className="sm:flex sm:items-center">
            <Button critical size="small" onClick={handleDelete}>
              Delete user
            </Button>
          </div>
        </Panel>
      </div>

      {deleteUser && (
        <ConfirmDialog title={`Confirm Delete: ${user?.name}`} onDismiss={handleDeleteConfirmation}>
          <h1 className="text-gray-700">Are you sure you want to delete this user?</h1>
          <Banner type="error" content="This action cannot be undone" />
        </ConfirmDialog>
      )}
    </div>
  )
}

export default UserPage
