import React, {HTMLAttributes, useContext, useState} from 'react'
import {Link} from 'gatsby'
import {ResponsiveLine} from '@nivo/line'
import {ResponsivePie} from '@nivo/pie'
import {animated} from '@react-spring/web'
import {EmptyIllustrationAlternate} from '../empty'
import {
  ArrowNarrowDownIconSmall,
  ArrowNarrowUpIconSmall,
  ChevronDownIconSmall,
  ChevronUpIconSmall,
  ExclamationCircleIcon,
} from '../icon'
import {DateRange, isAdmin, Role, Stat, StatMetric, StatQualifier, Tag, User} from '../../models'
import {isAccessible, shadeColor} from '../../utils/color'
import {Avatar, Column, Panel, SectionHeading, Select, Table} from '../tailwind'
import {AppContext} from '../../context'
import {toDateString, toShortDate} from '../../../common'

const calculateDelta = (from: number, to: number): number => {
  return Math.round(((to - from) / from) * 100)
}

const formatDateAsSeconds = (date: Date, padMinutes = false): string =>
  `${padMinutes ? `0${date.getMinutes()}`.slice(-2) : date.getMinutes()}:${`0${date.getSeconds()}`.slice(-2)}`

export const formatSeconds = (seconds: number): string =>
  seconds
    ? `${seconds < 0 ? '-' : ''}${
        Math.abs(seconds) > 3600 ? `${Math.floor(Math.abs(seconds) / 3600)}:` : ''
      }${formatDateAsSeconds(new Date(Math.abs(seconds - (seconds < 0 ? 1 : 0)) * 1000), Math.abs(seconds) > 3600)}`
    : '--:--'

export const formatPercentage = (value: number): string => (value || value === 0 ? `${Math.round(value * 100)}%` : '')

export interface EmptyProps extends HTMLAttributes<HTMLElement> {
  title: string
}

export const Empty: React.FC<EmptyProps> = ({title, ...props}) => (
  <div {...props} className="relative w-full h-full mt-8 space-y-12 grid place-items-center">
    <EmptyIllustrationAlternate className="w-4/5 max-w-xl" />
    <div className="space-y-4">
      <h1 className="text-gray-800 text-2xl font-extralight">{title}</h1>
    </div>
  </div>
)

export interface StatBoxProps {
  title: string
  value: number
  from?: number
  format?: 'none' | 'percentage' | 'time'
  delta?: number
  inverted?: boolean
  grayscale?: boolean
  description?: string
  id?: string
}

export const StatBox: React.FC<StatBoxProps> = ({
  title,
  value,
  from,
  format,
  delta,
  description,
  id,
  inverted = false,
  grayscale = false,
}) => {
  const isIncrease = delta && delta > 0
  const isPositive = inverted ? !isIncrease : isIncrease

  let valueString: string
  let fromString: string

  switch (format) {
    case 'percentage':
      valueString = formatPercentage(value)
      fromString = formatPercentage(from)
      break
    case 'time':
      valueString = formatSeconds(value)
      fromString = formatSeconds(from)
      break

    default:
      valueString = String(value)
      fromString = String(from)
      break
  }

  const showFrom = from || from === 0
  const showDelta = delta && delta !== Infinity

  return (
    <div className="px-4 py-5 sm:p-6">
      <dl>
        <dt className="text-base leading-6 font-normal text-gray-900 flex items-center space-x-1">
          <span>{title}</span>
          {description && (
            <span title={description}>
              <ExclamationCircleIcon className="w-4 h-4 text-blue-500" />
            </span>
          )}
        </dt>
        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
          <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
            {id ? <a href={`/team-stats/${encodeURIComponent(id)}`}>{valueString}</a> : valueString}
            {showFrom ? (
              <span className="ml-2 text-sm leading-5 font-medium text-gray-500">from {fromString}</span>
            ) : (
              ''
            )}
          </div>
          {showDelta ? (
            <div
              className={`inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 ${
                grayscale
                  ? 'bg-gray-100 text-gray-800'
                  : isPositive
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800'
              } md:mt-2 lg:mt-0`}
            >
              {isIncrease && (
                <ArrowNarrowUpIconSmall
                  className={`-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 ${
                    grayscale ? 'text-gray-500' : isPositive ? 'text-green-500' : 'text-red-500'
                  }`}
                />
              )}
              {!isIncrease && (
                <ArrowNarrowDownIconSmall
                  className={`-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 ${
                    grayscale ? 'text-gray-500' : isPositive ? 'text-green-500' : 'text-red-500'
                  }`}
                />
              )}
              <span className="sr-only">{isIncrease ? 'Increased' : 'Decreased'} by</span>
              {delta}%
            </div>
          ) : (
            ''
          )}
        </dd>
      </dl>
    </div>
  )
}

export interface Dimension {
  name?: string
  tag?: Tag
  provider?: User

  totalPatientsServedId?: string
  totalPatientsServed: number
  totalPatientsServedPrev?: number
  totalPatientsServedDelta?: number

  avgServeTime: number
  avgServeTimePrev?: number
  avgServeTimeDelta?: number

  avgCompleteDelta: number
  avgCompleteDeltaPrev?: number
  avgCompleteDeltaDelta?: number

  percentCompleteEarlyId?: string
  percentCompleteEarly: number
  percentCompleteEarlyPrev?: number
  percentCompleteEarlyDelta?: number

  percentCompleteOnTimeId?: string
  percentCompleteOnTime: number
  percentCompleteOnTimePrev?: number
  percentCompleteOnTimeDelta?: number

  percentCompleteLateId?: string
  percentCompleteLate: number
  percentCompleteLatePrev?: number
  percentCompleteLateDelta?: number

  avgSeatedDelta: number
  avgSeatedDeltaPrev?: number
  avgSeatedDeltaDelta?: number

  percentSeatedOnTimeId?: string
  percentSeatedOnTime: number
  percentSeatedOnTimePrev?: number
  percentSeatedOnTimeDelta?: number

  percentSeatedLateId?: string
  percentSeatedLate: number
  percentSeatedLatePrev?: number
  percentSeatedLateDelta?: number

  percentSeatedVeryLateId?: string
  percentSeatedVeryLate: number
  percentSeatedVeryLatePrev?: number
  percentSeatedVeryLateDelta?: number

  avgWaitTime: number
  avgWaitTimePrev?: number
  avgWaitTimeDelta?: number

  avgLength: number
  avgLengthPrev?: number
  avgLengthDelta?: number
}

export const createDimension = (stats: Stat[], prevStats: Stat[], tag?: Tag, provider?: User): Dimension => {
  const getStat = (metric: string, targetStats: Stat[] = stats): Stat => {
    return targetStats.find(
      (stat) =>
        stat.metric === metric &&
        (tag ? stat.tag?.id === tag.id : !stat.id.startsWith('tag#')) &&
        (provider ? stat.provider?.id === provider.id : !stat.id.startsWith('provider#'))
    )
  }

  const totalPatientsServed = getStat(StatMetric.TOTAL_PATIENTS_SERVED)
  const totalPatientsServedPrev = getStat(StatMetric.TOTAL_PATIENTS_SERVED, prevStats)

  const avgServeTime = getStat(StatMetric.AVG_SERVE_TIME)
  const avgServeTimePrev = getStat(StatMetric.AVG_SERVE_TIME, prevStats)

  const avgWaitTime = getStat(StatMetric.AVG_WAIT_TIME)
  const avgWaitTimePrev = getStat(StatMetric.AVG_WAIT_TIME, prevStats)

  const avgLength = getStat(StatMetric.AVG_LENGTH)
  const avgLengthPrev = getStat(StatMetric.AVG_LENGTH, prevStats)

  const avgCompleteDelta = getStat(StatMetric.AVG_COMPLETE_DELTA)
  const avgCompleteDeltaPrev = getStat(StatMetric.AVG_COMPLETE_DELTA, prevStats)

  const percentCompleteEarly = getStat(StatMetric.PERCENT_COMPLETE_EARLY)
  const percentCompleteEarlyPrev = getStat(StatMetric.PERCENT_COMPLETE_EARLY, prevStats)

  const percentCompleteOnTime = getStat(StatMetric.PERCENT_COMPLETE_ON_TIME)
  const percentCompleteOnTimePrev = getStat(StatMetric.PERCENT_COMPLETE_ON_TIME, prevStats)

  const percentCompleteLate = getStat(StatMetric.PERCENT_COMPLETE_LATE)
  const percentCompleteLatePrev = getStat(StatMetric.PERCENT_COMPLETE_LATE, prevStats)

  const avgSeatedDelta = getStat(StatMetric.AVG_SEATED_DELTA)
  const avgSeatedDeltaPrev = getStat(StatMetric.AVG_SEATED_DELTA, prevStats)

  const percentSeatedOnTime = getStat(StatMetric.PERCENT_SEATED_ON_TIME)
  const percentSeatedOnTimePrev = getStat(StatMetric.PERCENT_SEATED_ON_TIME, prevStats)

  const percentSeatedLate = getStat(StatMetric.PERCENT_SEATED_LATE)
  const percentSeatedLatePrev = getStat(StatMetric.PERCENT_SEATED_LATE, prevStats)

  const percentSeatedVeryLate = getStat(StatMetric.PERCENT_SEATED_VERY_LATE)
  const percentSeatedVeryLatePrev = getStat(StatMetric.PERCENT_SEATED_VERY_LATE, prevStats)

  return {
    name: tag?.name ?? provider?.name ?? 'global',
    tag,
    provider,
    totalPatientsServedId: totalPatientsServed?.id,
    totalPatientsServed: totalPatientsServed?.value,
    totalPatientsServedPrev: totalPatientsServedPrev?.value,
    totalPatientsServedDelta:
      totalPatientsServedPrev && calculateDelta(totalPatientsServedPrev?.value, totalPatientsServed?.value),
    avgServeTime: avgServeTime?.value,
    avgServeTimePrev: avgServeTimePrev?.value,
    avgServeTimeDelta: avgServeTimePrev && calculateDelta(avgServeTimePrev?.value, avgServeTime?.value),
    avgWaitTime: avgWaitTime?.value,
    avgWaitTimePrev: avgWaitTimePrev?.value,
    avgWaitTimeDelta: avgWaitTimePrev && calculateDelta(avgWaitTimePrev?.value, avgWaitTime?.value),
    avgLength: avgLength?.value,
    avgLengthPrev: avgLengthPrev?.value,
    avgLengthDelta: avgLengthPrev && calculateDelta(avgLengthPrev?.value, avgLength?.value),
    avgCompleteDelta: avgCompleteDelta?.value,
    avgCompleteDeltaPrev: avgCompleteDeltaPrev?.value,
    avgCompleteDeltaDelta: avgCompleteDeltaPrev && calculateDelta(avgCompleteDeltaPrev?.value, avgCompleteDelta?.value),
    percentCompleteEarlyId: percentCompleteEarly?.id,
    percentCompleteEarly: percentCompleteEarly?.value,
    percentCompleteEarlyPrev: percentCompleteEarlyPrev?.value,
    percentCompleteEarlyDelta:
      percentCompleteEarlyPrev && calculateDelta(percentCompleteEarlyPrev?.value, percentCompleteEarly?.value),
    percentCompleteOnTimeId: percentCompleteOnTime?.id,
    percentCompleteOnTime: percentCompleteOnTime?.value,
    percentCompleteOnTimePrev: percentCompleteOnTimePrev?.value,
    percentCompleteOnTimeDelta:
      percentCompleteOnTimePrev && calculateDelta(percentCompleteOnTimePrev?.value, percentCompleteOnTime?.value),
    percentCompleteLateId: percentCompleteLate?.id,
    percentCompleteLate: percentCompleteLate?.value,
    percentCompleteLatePrev: percentCompleteLatePrev?.value,
    percentCompleteLateDelta:
      percentCompleteLatePrev && calculateDelta(percentCompleteLatePrev?.value, percentCompleteLate?.value),
    avgSeatedDelta: avgSeatedDelta?.value,
    avgSeatedDeltaPrev: avgSeatedDeltaPrev?.value,
    avgSeatedDeltaDelta: avgSeatedDeltaPrev && calculateDelta(avgSeatedDeltaPrev?.value, avgSeatedDelta?.value),
    percentSeatedOnTimeId: percentSeatedOnTime?.id,
    percentSeatedOnTime: percentSeatedOnTime?.value,
    percentSeatedOnTimePrev: percentSeatedOnTimePrev?.value,
    percentSeatedOnTimeDelta:
      percentSeatedOnTimePrev && calculateDelta(percentSeatedOnTimePrev?.value, percentSeatedOnTime?.value),
    percentSeatedLateId: percentSeatedLate?.id,
    percentSeatedLate: percentSeatedLate?.value,
    percentSeatedLatePrev: percentSeatedLatePrev?.value,
    percentSeatedLateDelta:
      percentSeatedLatePrev && calculateDelta(percentSeatedLatePrev?.value, percentSeatedLate?.value),
    percentSeatedVeryLateId: percentSeatedVeryLate?.id,
    percentSeatedVeryLate: percentSeatedVeryLate?.value,
    percentSeatedVeryLatePrev: percentSeatedVeryLatePrev?.value,
    percentSeatedVeryLateDelta:
      percentSeatedVeryLatePrev && calculateDelta(percentSeatedVeryLatePrev?.value, percentSeatedVeryLate?.value),
  }
}

export type Qualifier = {
  id: StatQualifier
  name: string
  dateRange?: DateRange
}

const dateNDaysAgo = (n: number): string => {
  const date = new Date()
  date.setDate(date.getDate() - n)
  return toDateString(date)
}

const today = toDateString(new Date())

export const qualifiers: Qualifier[] = [
  {id: StatQualifier.TODAY, name: 'Today', dateRange: {startDate: today, endDate: today}},
  {id: StatQualifier.YESTERDAY, name: 'Yesterday', dateRange: {startDate: dateNDaysAgo(1), endDate: dateNDaysAgo(1)}},
  {id: StatQualifier.LAST_7_DAYS, name: 'Last 7 Days', dateRange: {startDate: dateNDaysAgo(7), endDate: today}},
  {id: StatQualifier.LAST_30_DAYS, name: 'Last 30 Days', dateRange: {startDate: dateNDaysAgo(30), endDate: today}},
  {id: StatQualifier.CUSTOM, name: 'Custom'},
  {id: StatQualifier.ALL_TIME, name: 'All Time'},
]

export interface NumericCellProps {
  value: number
  delta?: number
  format?: 'none' | 'percentage' | 'time'
  color?: 'normal' | 'inverted' | 'gray'
  id?: string
}

export const NumericCell: React.FC<NumericCellProps> = ({value, delta, format, color, id}) => {
  const display = format === 'percentage' ? formatPercentage(value) : format === 'time' ? formatSeconds(value) : value
  return (
    <div className="flex items-center space-x-1">
      {id ? <a href={`/team-stats/${encodeURIComponent(id)}`}>{display}</a> : <span>{display}</span>}
      {value && delta ? (
        delta > 0 ? (
          <ChevronUpIconSmall
            className={`${
              color === 'inverted' ? 'text-red-500' : color === 'gray' ? 'text-gray-500' : 'text-green-500'
            } w-5 h-5`}
          />
        ) : (
          <ChevronDownIconSmall
            className={`${
              color === 'inverted' ? 'text-green-500' : color === 'gray' ? 'text-gray-500' : 'text-red-500'
            } w-5 h-5`}
          />
        )
      ) : (
        ''
      )}
    </div>
  )
}

export interface TagBreakdownProps {
  stats: Stat[]
  prevStats: Stat[]
  provider?: User
}

export const TagBreakdown: React.FC<TagBreakdownProps> = ({stats, prevStats, provider}) => {
  const dimensions = [...new Set(stats.filter((stat) => stat.tag).map((stat) => stat.tag))]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((tag) => createDimension(stats, prevStats, tag, provider))

  return (
    <div>
      <SectionHeading title="Tag Breakdown" divider={false} />

      <Table
        items={dimensions}
        columns={[
          {
            name: 'Tag',
            value: (d) => (
              <div
                key={d.tag.name}
                className="px-2 py-1 font-semibold uppercase rounded-sm w-full"
                style={{
                  backgroundColor: d.tag.color,
                  fontSize: '11px',
                  color: isAccessible(d.tag.color) ? 'white' : shadeColor(d.tag.color, -40),
                }}
              >
                <Link to={`/team-stats/tags/${d.tag?.id}`}>{d.tag.name}</Link>
              </div>
            ),
          },
          {
            name: '# of Patients',
            value: (d) => <NumericCell value={d.totalPatientsServed} delta={d.totalPatientsServedDelta} />,
          },
          {
            name: 'Avg. Wait Time',
            value: (d) => (
              <NumericCell value={d.avgWaitTime} delta={d.avgWaitTimeDelta} format="time" color="inverted" />
            ),
          },
          {
            name: 'Avg. Serve Time',
            value: (d) => (
              <NumericCell value={d.avgServeTime} delta={d.avgServeTimeDelta} format="time" color="inverted" />
            ),
          },
          {
            name: 'Avg. Appt Length',
            value: (d) => <NumericCell value={d.avgLength} delta={d.avgLengthDelta} format="time" color="inverted" />,
          },
        ]}
      />
    </div>
  )
}

export interface ProviderBreakdownProps {
  stats: Stat[]
  prevStats: Stat[]
  provider?: User
  showServeAndLengthColumns?: boolean
}

export const ProviderBreakdown: React.FC<ProviderBreakdownProps> = ({stats, prevStats, showServeAndLengthColumns}) => {
  const dimensions = [
    ...new Set(
      stats
        .filter((stat) => stat.provider && stat.provider.roles.includes(Role.DOCTOR))
        .map((stat) => stat.provider)
        .sort((a, b) => a.name.localeCompare(b.name))
    ),
    ...new Set(
      stats
        .filter((stat) => stat.provider && stat.provider.roles.includes(Role.HYGIENE))
        .map((stat) => stat.provider)
        .sort((a, b) => a.name.localeCompare(b.name))
    ),
  ].map((provider) => createDimension(stats, prevStats, undefined, provider))

  const columns: Column<Dimension>[] = [
    {
      name: 'Provider',
      value: (d) => (
        <div className="flex items-center space-x-2">
          <Avatar user={d.provider} size="tiny" />
          <Link
            to={`/team-stats/providers/${d.provider?.id}`}
            className="font-medium text-indigo-600 hover:text-indigo-900"
          >
            {d.provider?.roles?.includes(Role.NON_USER) ? `First Available ${d.name}` : d.name}
          </Link>
        </div>
      ),
    },
    {
      name: '# of Patients',
      value: (d) => (
        <NumericCell id={d.totalPatientsServedId} value={d.totalPatientsServed} delta={d.totalPatientsServedDelta} />
      ),
    },
    {
      name: 'Avg. Wait Time',
      value: (d) => <NumericCell value={d.avgWaitTime} delta={d.avgWaitTimeDelta} format="time" color="inverted" />,
    },
  ]

  if (showServeAndLengthColumns) {
    columns.push(
      {
        name: 'Avg. Serve Time',
        value: (d) => <NumericCell value={d.avgServeTime} delta={d.avgServeTimeDelta} format="time" color="inverted" />,
      },
      {
        name: 'Avg. Appt Length',
        value: (d) => <NumericCell value={d.avgLength} delta={d.avgLengthDelta} format="time" color="inverted" />,
      }
    )
  } else {
    columns.push(
      {
        name: '% Early',
        value: (d) => (
          <NumericCell
            id={d.percentCompleteEarlyId}
            value={d.percentCompleteEarly}
            delta={d.percentCompleteEarlyDelta}
            format="percentage"
          />
        ),
      },
      {
        name: '% On-Time',
        value: (d) => (
          <NumericCell
            id={d.percentCompleteOnTimeId}
            value={d.percentCompleteOnTime}
            delta={d.percentCompleteOnTimeDelta}
            format="percentage"
            color="gray"
          />
        ),
      },
      {
        name: '% Late',
        value: (d) => (
          <NumericCell
            id={d.percentCompleteLateId}
            value={d.percentCompleteLate}
            delta={d.percentCompleteLateDelta}
            format="percentage"
            color="inverted"
          />
        ),
      }
    )
  }

  return (
    <div>
      <SectionHeading title="Provider Breakdown" divider={false} />

      <Table items={dimensions} columns={columns} />
    </div>
  )
}

export const CompletionTimeBreakdown: React.FC<{dimension: Dimension}> = ({dimension}) => {
  const data = [
    {
      id: 'early',
      label: 'Early',
      value: dimension.percentCompleteEarly * 100,
      color: '#10B981',
    },
    {
      id: 'on-time',
      label: 'On-Time',
      value: dimension.percentCompleteOnTime * 100,
      color: '#FCD34D',
    },
    {
      id: 'late',
      label: 'Late',
      value: dimension.percentCompleteLate * 100,
      color: '#DC2626',
    },
  ]

  return (
    <div>
      <SectionHeading title="Completion Time Breakdown" divider={false} />
      <div className="sm:grid sm:grid-cols-2">
        <div className="h-96">
          <ResponsivePie
            enableArcLabels
            data={data}
            colors={{datum: 'data.color'}}
            margin={{top: 0, right: 20, bottom: 0, left: 20}}
            arcLabel={(datum) => (datum.value ? `${datum.label}|${datum.value.toFixed(0)}%` : undefined)}
            arcLabelsRadiusOffset={0.6}
            arcLabelsTextColor="white"
            arcLabelsComponent={({label, style}) => (
              <animated.g transform={style.transform} style={{pointerEvents: 'none'}}>
                {label && (
                  <text
                    className="text-base sm:text-lg font-semibold"
                    textAnchor="middle"
                    dominantBaseline="central"
                    fill={style.textColor}
                  >
                    <tspan x="0">{label.split('|')[0]}</tspan>
                    <tspan x="0.2em" dy="1.2em">
                      {label.split('|')[1]}
                    </tspan>
                  </text>
                )}
              </animated.g>
            )}
            enableArcLinkLabels={false}
            isInteractive={false}
            animate={false}
          />
        </div>
        <div className="grid grid-cols-1 sm:rounded-lg bg-white overflow-hidden shadow">
          <div className="border-l-8" style={{borderLeftColor: '#10B981'}}>
            <StatBox
              title="% Early"
              format="percentage"
              id={dimension.percentCompleteEarlyId}
              value={dimension.percentCompleteEarly}
              from={dimension.percentCompleteEarlyPrev}
              delta={dimension.percentCompleteEarlyDelta}
              description="Percentage of appointments that are completed early (at least 5 minutes before the scheduled end time)."
            />
          </div>
          <div className="border-t border-gray-200 border-l-8" style={{borderLeftColor: '#FCD34D'}}>
            <StatBox
              grayscale
              title="% On-Time"
              format="percentage"
              id={dimension.percentCompleteOnTimeId}
              value={dimension.percentCompleteOnTime}
              from={dimension.percentCompleteOnTimePrev}
              delta={dimension.percentCompleteOnTimeDelta}
              description="Percentage of appointments that are completed on-time (from 5 minutes before to 5 minutes after the scheduled end time)."
            />
          </div>
          <div className="border-t border-gray-200 border-l-8" style={{borderLeftColor: '#DC2626'}}>
            <StatBox
              inverted
              title="% Late"
              format="percentage"
              id={dimension.percentCompleteLateId}
              value={dimension.percentCompleteLate}
              from={dimension.percentCompleteLatePrev}
              delta={dimension.percentCompleteLateDelta}
              description="Percentage of appointments that are completed late (at least 5 minutes after the scheduled end time)."
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export const LastUpdated: React.FC<{stats: Stat[]}> = ({stats}) => {
  const lastUpdated =
    stats[0] &&
    new Date(stats[0].updatedAt * 1000).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    })

  return <p className="text-base text-gray-600">Last updated {lastUpdated}</p>
}

const getDatesBetweenDates = (startDate: string | Date, endDate: string | Date): string[] => {
  const dates: string[] = []

  const fromDate = new Date(startDate)
  const toDate = new Date(endDate)
  // eslint-disable-next-line no-unmodified-loop-condition
  while (fromDate < toDate) {
    dates.push(new Date(fromDate).toISOString().split('T')[0])
    fromDate.setUTCDate(fromDate.getUTCDate() + 1)
  }

  return dates
}

export const MetricCharts: React.FC<{stats: Stat[]; dateRange: DateRange}> = ({stats, dateRange}) => {
  const byDateStats = stats.filter((stat) => stat.date)
  // const byDatePrevStats = prevStats.filter((stat) => stat.date)

  const chartableMetrics = [
    {name: 'Total Patients Served', id: StatMetric.TOTAL_PATIENTS_SERVED},
    {name: 'Avg. Wait Time', id: StatMetric.AVG_WAIT_TIME, format: 'time'},
    {name: 'Avg. Time Before Seated', id: StatMetric.AVG_SEATED_DELTA, format: 'time'},
    {name: 'Avg. Serve Time', id: StatMetric.AVG_SERVE_TIME, format: 'time'},
    {name: 'Avg. Appt Length', id: StatMetric.AVG_LENGTH, format: 'time'},
  ]
  const [chartedMetric, setChartedMetric] = useState(
    chartableMetrics.find((metric) => metric.id === StatMetric.TOTAL_PATIENTS_SERVED)
  )

  const allDates = getDatesBetweenDates(dateRange.startDate, dateRange.endDate)
  // eslint-disable-next-line unicorn/no-null
  if (!allDates || allDates.length === 0) return null

  const chartedData: {x: string; y: number}[] = []

  for (const chartedDate of allDates) {
    const existing = byDateStats.find(({metric, date}) => date === chartedDate && metric === chartedMetric.id)

    chartedData.push({
      x: toShortDate(chartedDate),
      // eslint-disable-next-line unicorn/no-null
      y: existing?.value || null,
    })
  }

  const data = [
    {
      id: chartedMetric.id,
      color: '#DF5C26',
      data: chartedData,
    },
  ]

  return (
    <div className="space-y-3">
      <div className="md:w-56">
        <Select
          value="id"
          display="name"
          items={chartableMetrics.filter((metric) => byDateStats.find((stat) => stat.metric === metric.id))}
          initial={chartedMetric}
          onSelection={(i) => setChartedMetric(i)}
        />
      </div>
      <Panel>
        <div className="h-96">
          <ResponsiveLine
            useMesh
            data={data}
            margin={{top: 30, right: 30, bottom: 30, left: 50}}
            xScale={{type: 'point'}}
            yScale={{type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false}}
            yFormat={chartedMetric.format === 'time' ? (v) => formatSeconds(v as number) : ' >-.0f'}
            colors={{datum: 'color'}}
            curve="linear"
            axisTop={undefined}
            axisRight={undefined}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: chartedMetric.format === 'time' ? (value) => formatSeconds(value as number) : undefined,
            }}
            pointSize={10}
            pointColor={{theme: 'background'}}
            pointBorderWidth={2}
            pointBorderColor={{from: 'serieColor'}}
            tooltip={({point}) => {
              return (
                <div
                  style={{
                    background: 'white',
                    padding: '9px 12px',
                    border: '1px solid #ccc',
                  }}
                >
                  {point.data.xFormatted} <strong className="ml-1">{point.data.yFormatted}</strong>
                </div>
              )
            }}
          />
        </div>
      </Panel>
    </div>
  )
}

export interface StatsSummaryProps {
  stats: Stat[]
  dateRange: DateRange
  prevStats: Stat[]
  provider?: User
  tag?: Tag
}

export const ProviderStatsSummary: React.FC<StatsSummaryProps> = ({provider, stats, prevStats, dateRange}) => {
  const defaultDimension = createDimension(stats, prevStats, undefined, provider)

  return (
    <div className="space-y-8">
      <MetricCharts stats={stats} dateRange={dateRange} />
      <div>
        <SectionHeading title="Overall" divider={false} />
        <div className="grid grid-cols-1 sm:rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
          <div>
            <StatBox
              title="Total Patients Served"
              id={defaultDimension.totalPatientsServedId}
              value={defaultDimension.totalPatientsServed}
              from={defaultDimension.totalPatientsServedPrev}
              delta={defaultDimension.totalPatientsServedDelta}
            />
          </div>
          <div className="border-t border-gray-200 md:border-0 md:border-l">
            <StatBox
              inverted
              title="Avg. Wait Time"
              format="time"
              value={defaultDimension.avgWaitTime}
              from={defaultDimension.avgWaitTimePrev}
              delta={defaultDimension.avgWaitTimeDelta}
            />
          </div>
          <div className="border-t border-gray-200 md:border-0 md:border-l" />
        </div>
      </div>

      <CompletionTimeBreakdown dimension={defaultDimension} />

      <TagBreakdown stats={stats} prevStats={prevStats} provider={provider} />

      <LastUpdated stats={stats} />
    </div>
  )
}

export const TagStatsSummary: React.FC<StatsSummaryProps> = ({tag, stats, prevStats, dateRange}) => {
  const defaultDimension = createDimension(stats, prevStats, tag, undefined)

  const {user} = useContext(AppContext)
  const canViewProviderStats = isAdmin(user) || user.roles.includes(Role.DOCTOR)

  return (
    <div className="space-y-8">
      <MetricCharts stats={stats} dateRange={dateRange} />

      <div>
        <SectionHeading title="Overall" divider={false} />
        <div className="grid grid-cols-1 sm:rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
          <div>
            <StatBox
              title="Total Patients"
              id={defaultDimension.totalPatientsServedId}
              value={defaultDimension.totalPatientsServed}
              from={defaultDimension.totalPatientsServedPrev}
              delta={defaultDimension.totalPatientsServedDelta}
            />
          </div>
          <div className="border-t border-gray-200 md:border-0 md:border-l">
            <StatBox
              inverted
              title="Avg. Wait Time"
              format="time"
              value={defaultDimension.avgWaitTime}
              from={defaultDimension.avgWaitTimePrev}
              delta={defaultDimension.avgWaitTimeDelta}
            />
          </div>
          <div className="border-t border-gray-200 md:border-0 md:border-l">
            <StatBox
              inverted
              title="Avg. Serve Time"
              format="time"
              value={defaultDimension.avgServeTime}
              from={defaultDimension.avgServeTimePrev}
              delta={defaultDimension.avgServeTimeDelta}
            />
          </div>
        </div>
      </div>

      <CompletionTimeBreakdown dimension={defaultDimension} />

      {canViewProviderStats && <ProviderBreakdown showServeAndLengthColumns stats={stats} prevStats={prevStats} />}

      <LastUpdated stats={stats} />
    </div>
  )
}

export const TeamStatsSummary: React.FC<StatsSummaryProps> = ({stats, prevStats, dateRange}) => {
  const overallStats = stats.filter((stat) => !stat.date)
  const overallPrevStats = prevStats.filter((stat) => !stat.date)

  const defaultDimension = createDimension(overallStats, overallPrevStats)

  const {user} = useContext(AppContext)
  const canViewProviderStats = isAdmin(user) || user.roles.includes(Role.DOCTOR)

  const data = [
    {
      id: 'on-time',
      label: 'On-Time',
      value: defaultDimension.percentSeatedOnTime * 100,
      color: '#10B981',
    },
    {
      id: 'late',
      label: 'Late',
      value: defaultDimension.percentSeatedLate * 100,
      color: '#FCD34D',
    },
    {
      id: 'very-late',
      label: 'Very Late',
      value: defaultDimension.percentSeatedVeryLate * 100,
      color: '#DC2626',
    },
  ]

  return (
    <div className="space-y-8">
      <MetricCharts stats={stats} dateRange={dateRange} />

      <div>
        <SectionHeading title="Overall" divider={false} />
        <div className="grid grid-cols-1 sm:rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
          <div>
            <StatBox
              title="Total Patients Served"
              id={defaultDimension.totalPatientsServedId}
              value={defaultDimension.totalPatientsServed}
              from={defaultDimension.totalPatientsServedPrev}
              delta={defaultDimension.totalPatientsServedDelta}
            />
          </div>
          <div className="border-t border-gray-200 md:border-0 md:border-l">
            <StatBox
              inverted
              title="Avg. Wait Time"
              format="time"
              value={defaultDimension.avgWaitTime}
              from={defaultDimension.avgWaitTimePrev}
              delta={defaultDimension.avgWaitTimeDelta}
            />
          </div>
          <div className="border-t border-gray-200 md:border-0 md:border-l">
            <StatBox
              inverted
              title="Avg. Time Before Seated"
              format="time"
              value={defaultDimension.avgSeatedDelta}
              from={defaultDimension.avgSeatedDeltaPrev}
              delta={defaultDimension.avgSeatedDeltaDelta}
              description="This is the average amount of time before or after the scheduled appointment start time that a patient is seated."
            />
          </div>
        </div>
      </div>

      <div>
        <SectionHeading title="Seated Time Breakdown" divider={false} />
        <div className="sm:grid sm:grid-cols-2">
          <div className="h-96">
            <ResponsivePie
              enableArcLabels
              data={data}
              colors={{datum: 'data.color'}}
              margin={{top: 0, right: 20, bottom: 0, left: 20}}
              arcLabel={(datum) => (datum.value ? `${datum.label}|${datum.value.toFixed(0)}%` : undefined)}
              arcLabelsRadiusOffset={0.6}
              arcLabelsTextColor="white"
              arcLabelsComponent={({label, style}) => (
                <animated.g transform={style.transform} style={{pointerEvents: 'none'}}>
                  {label && (
                    <text
                      className="text-base sm:text-lg font-semibold"
                      textAnchor="middle"
                      dominantBaseline="central"
                      fill={style.textColor}
                    >
                      <tspan x="0">{label.split('|')[0]}</tspan>
                      <tspan x="0.2em" dy="1.2em">
                        {label.split('|')[1]}
                      </tspan>
                    </text>
                  )}
                </animated.g>
              )}
              enableArcLinkLabels={false}
              isInteractive={false}
              animate={false}
            />
          </div>
          <div className="grid grid-cols-1 sm:rounded-lg bg-white overflow-hidden shadow">
            <div className="border-l-8" style={{borderLeftColor: '#10B981'}}>
              <StatBox
                title="% On-Time"
                format="percentage"
                id={defaultDimension.percentSeatedOnTimeId}
                value={defaultDimension.percentSeatedOnTime}
                from={defaultDimension.percentSeatedOnTimePrev}
                delta={defaultDimension.percentSeatedOnTimeDelta}
                description="Percentage of appointments that are seated on time (within 5 minutes of the scheduled start time)."
              />
            </div>
            <div className="border-t border-gray-200 border-l-8" style={{borderLeftColor: '#FCD34D'}}>
              <StatBox
                inverted
                title="% Late"
                format="percentage"
                id={defaultDimension.percentSeatedLateId}
                value={defaultDimension.percentSeatedLate}
                from={defaultDimension.percentSeatedLatePrev}
                delta={defaultDimension.percentSeatedLateDelta}
                description="Percentage of appointments that are seated late (from 5 to 15 minutes after the scheduled start time)."
              />
            </div>
            <div className="border-t border-gray-200 border-l-8" style={{borderLeftColor: '#DC2626'}}>
              <StatBox
                inverted
                title="% Very Late"
                format="percentage"
                id={defaultDimension.percentSeatedVeryLateId}
                value={defaultDimension.percentSeatedVeryLate}
                from={defaultDimension.percentSeatedVeryLatePrev}
                delta={defaultDimension.percentSeatedVeryLateDelta}
                description="Percentage of appointments that are seated very late (more than 15 minutes after the scheduled start time)."
              />
            </div>
          </div>
        </div>
      </div>

      <CompletionTimeBreakdown dimension={defaultDimension} />

      {canViewProviderStats && <ProviderBreakdown stats={stats} prevStats={prevStats} />}

      <TagBreakdown stats={stats} prevStats={prevStats} />

      <LastUpdated stats={stats} />
    </div>
  )
}

export * from './page'
