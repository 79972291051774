import React, {useState, useEffect, HTMLAttributes, useRef} from 'react'
import {
  CalendarIcon,
  ChairIcon,
  CheckCircleIconSmall,
  CheckIcon,
  CheckIconSmall,
  ClockIcon,
  ExclamationCircleIconSmall,
  PencilAltIcon,
  PencilAltIconSmall,
  ToothIcon,
  TrashIcon,
  TrashIconSmall,
} from '../icon'
import {Appointment, getRule, getRuleStyle, Role, Rule, Status} from '../../models'
import {Button, Menu, MenuDivider, MenuItem} from '../tailwind'
import {isAccessible, shadeColor} from '../../utils/color'
import {useClickOutside} from '../../hooks'
import {DuplicateIconSmall} from '..'

const formatDateAsSeconds = (date: Date, padMinutes = false): string =>
  `${padMinutes ? `0${date.getMinutes()}`.slice(-2) : date.getMinutes()}:${`0${date.getSeconds()}`.slice(-2)}`
const formatSeconds = (seconds: number): string =>
  seconds
    ? `${seconds < 0 ? '-' : ''}${
        Math.abs(seconds) > 3600 ? `${Math.floor(Math.abs(seconds) / 3600)}:` : ''
      }${formatDateAsSeconds(new Date(Math.abs(seconds - (seconds < 0 ? 1 : 0)) * 1000), Math.abs(seconds) > 3600)}`
    : '--:--'

const getFormattedTimeSince = (reference: number): string => formatSeconds((Date.now() - reference) / 1000)
const getTimeSince = (last: number): string => getFormattedTimeSince(last * 1000)
const getProgress = (start: number, length: number) => (Date.now() - start * 1000) / (length * 60 * 1000)
const getSecondsRemaining = (start: number, length: number, reference: number = Date.now()) =>
  (length * 60 * 1000 - (reference - start * 1000)) / 1000

const getCurrentRule = (appointment: Appointment, rules: Rule[]): Rule | undefined => {
  if (!rules) return undefined

  const isPending = appointment.status === Status.PENDING
  const filteredRules = rules.filter((rule) => !rule.status || rule.status === appointment.status)
  if (filteredRules.length === 0) return undefined

  return getRule(
    filteredRules,
    (Date.now() - (isPending ? appointment.scheduled : appointment.lastStatusChangedAt) * 1000) / 1000
  )
}

interface CountdownTimerProps extends HTMLAttributes<HTMLElement> {
  start: number
  length: number
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({start, length, ...props}) => {
  const [secondsRemaining, setSecondsRemaining] = useState(getSecondsRemaining(start, length))

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsRemaining(getSecondsRemaining(start, length))
    }, 1000)
    return (): void => clearInterval(interval)
  }, [length, start])

  return <span {...props}>{formatSeconds(secondsRemaining)}</span>
}

const Timer: React.FC<{start: number}> = ({start}) => {
  const [timeSince, setTimeSince] = useState(getTimeSince(start))

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeSince(getTimeSince(start))
    }, 1000)
    return (): void => clearInterval(interval)
  }, [start])

  return <span>{timeSince}</span>
}

interface ProgressBarProps extends HTMLAttributes<HTMLElement> {
  progress: number
  scheduled: boolean
}

const ProgressBar: React.FC<ProgressBarProps> = ({progress, scheduled, className, ...props}) => {
  return progress < 0 ? (
    <div />
  ) : (
    <div
      {...props}
      className={`${className} border-b-4`}
      style={{
        width: `${Math.min(progress * 100, 100)}%`,
        borderColor: scheduled ? '#5D5D5D' : progress >= 1 ? '#C60000' : progress >= 0.75 ? '#FFEB01' : '#0FC600',
      }}
    />
  )
}

interface ProgressProps extends HTMLAttributes<HTMLElement> {
  start: number
  length: number
  scheduled: boolean
}

const Progress: React.FC<ProgressProps> = ({start, length, scheduled, ...props}) => {
  const [progress, setProgress] = useState(getProgress(start, length))

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(getProgress(start, length))
    }, 1000)
    return (): void => clearInterval(interval)
  }, [length, start])

  return <ProgressBar progress={progress} scheduled={scheduled} {...props} />
}

const MobileTextGroup: React.FC<HTMLAttributes<HTMLElement>> = ({children, className, ...props}) => (
  <div {...props} className={`${className} leading-4 text-sm font-extralight tracking-tight`}>
    {children}
  </div>
)

export interface AppointmentCardProps {
  appointment: Appointment
  compact?: boolean
  rules: Rule[]
  onEdit?: (appointment: Appointment) => void
  onDelete?: (appointment: Appointment) => void
  onComplete?: (appointment: Appointment) => void
  onSplit?: (appointment: Appointment) => void
  onClaimed?: (appointment: Appointment) => void
  onWaiting?: (appointment: Appointment) => void
  onSeated?: (appointment: Appointment) => void
  onPending?: (appointment: Appointment) => void
}

// eslint-disable-next-line complexity
export const AppointmentCard: React.FC<AppointmentCardProps> = ({
  appointment,
  rules,
  compact = false,
  onEdit,
  onDelete,
  onComplete,
  onSplit,
  onClaimed,
  onWaiting,
  onSeated,
  onPending,
  ...props
}) => {
  const initialRule = getCurrentRule(appointment, rules)
  const [currentRule, setCurrentRule] = useState(initialRule)
  const currentRuleStyle = getRuleStyle(currentRule)
  const scheduled = appointment.status === Status.PENDING

  useEffect(() => {
    const interval = setInterval(() => {
      const newRule = getCurrentRule(appointment, rules)
      if (newRule !== currentRule) setCurrentRule(newRule)
    }, 1000)
    return (): void => clearInterval(interval)
  }, [appointment, appointment.status, currentRule, rules])

  const handleEdit = () => {
    if (onEdit) onEdit(appointment)
  }

  const handleDelete = () => {
    if (onDelete) onDelete(appointment)
  }

  const handleComplete = () => {
    if (onComplete) onComplete(appointment)
  }

  const handleSplit = () => {
    if (onSplit) onSplit(appointment)
  }

  const handleClaimed = () => {
    if (onClaimed) onClaimed(appointment)
  }

  const handleWaiting = () => {
    if (onWaiting) onWaiting(appointment)
  }

  const handleSeated = () => {
    if (onSeated) onSeated(appointment)
  }

  const handlePending = () => {
    if (onPending) onPending(appointment)
  }

  const timeText = scheduled ? (
    appointment.scheduledDisplay
  ) : appointment.completed ? (
    formatSeconds(appointment.totalDuration)
  ) : (
    <Timer start={appointment.lastStatusChangedAt} />
  )

  const time = (
    <div className={`${compact ? 'col-span-1 text-left' : 'col-span-2 text-center'} sm:text-right`}>
      <span
        className={`${
          compact
            ? 'text-sm md:text-base'
            : scheduled
            ? 'text-xl sm:text-2xl md:text-3xl'
            : 'text-3xl sm:text-4xl md:text-5xl'
        } font-extralight md:tracking-wider`}
      >
        {timeText}
      </span>
    </div>
  )

  const tags = (
    <ul className={`col-span-2 ${compact ? 'space-y-px' : 'space-y-px sm:space-y-1'}`}>
      {appointment.tags?.map((tag) => (
        <li
          key={tag.name}
          className={`${compact ? 'px-1 py-1' : 'px-2 py-1'} font-semibold rounded-sm truncate`}
          style={{
            backgroundColor: tag.color,
            fontSize: '11px',
            color: isAccessible(tag.color) ? 'white' : shadeColor(tag.color, -40),
          }}
        >
          <span className="hidden sm:block">{compact ? undefined : tag.name}</span>
        </li>
      ))}
    </ul>
  )

  const mobileTags = (
    <div className="flex h-8 space-x-1">
      {appointment.tags?.map((tag) => (
        <span
          key={tag.name}
          className="h-full w-2"
          style={{
            // width: '4px',
            backgroundColor: tag.color,
          }}
        />
      ))}
    </div>
  )

  const isFirstAvailable = appointment.provider?.roles?.includes(Role.FIRST_AVAILABLE)
  const providerNames = appointment.provider?.name?.split(' ')
  const providerName = isFirstAvailable
    ? appointment.provider?.name
    : providerNames && providerNames.length > 1
    ? providerNames[1]
    : undefined

  const providerText = <span className={`${isFirstAvailable && 'italic'}`}>{providerName}</span>

  const provider = (
    <div
      className={`leading-none ${
        compact ? 'col-span-2 text-center sm:col-span-1 sm:text-left' : 'col-span-3 sm:col-span-2'
      }`}
    >
      <span className={`${compact ? 'text-sm md:text-base' : 'text-sm md:text-2xl'} font-extralight sm:tracking-wider`}>
        {providerText}
      </span>
    </div>
  )

  const requestingNames = appointment.requesting?.name?.split(' ')
  const requestingName =
    requestingNames && requestingNames.length > 1 ? `${requestingNames[0]} ${requestingNames[1][0]}` : undefined

  const roomText = appointment.room?.name

  const roomAndRequesting = (
    <div
      className={`leading-3 ${!compact && 'mt-1'} text-xs font-extralight tracking-tight md:tracking-normal truncate`}
    >
      <p>{roomText}</p>
      <p>{requestingName}</p>
    </div>
  )

  const totalTimer = appointment.completed ? (
    formatSeconds(getSecondsRemaining(appointment.start, appointment.length, appointment.lastStatusChangedAt * 1000))
  ) : (
    <CountdownTimer start={appointment.start} length={appointment.length} />
  )

  const showTotalTimer = appointment.completed || appointment.start < Date.now() / 1000

  const patientText = appointment.patient
  const totalTimerText = showTotalTimer ? totalTimer : ''

  const patient = (
    <div className="col-span-3 sm:col-span-4 leading-4">
      <p
        className={`text-xl md:text-2xl font-extralight sm:tracking-wide truncate ${showTotalTimer && 'mt-2 sm:mt-3'}`}
      >
        {patientText}
      </p>
      <p className="text-xs font-extralight tracking-wider" style={{fontSize: '12px'}}>
        {totalTimerText}
      </p>
    </div>
  )

  const CompactDetail: React.FC<HTMLAttributes<HTMLElement>> = ({children, className, ...props}) => (
    <span
      className={`${className} text-sm font-extralight sm:mr-2 sm:tracking-wide md:text-base md:mr-3 md:tracking-wider`}
      {...props}
    >
      {children}
    </span>
  )

  const compactDetails = (
    <>
      <div className="hidden sm:block col-span-7">
        <CompactDetail>{appointment.room?.name}</CompactDetail>
        <CompactDetail>•</CompactDetail>
        <CompactDetail>{requestingName}</CompactDetail>
        <CompactDetail>•</CompactDetail>
        <CompactDetail>{appointment.patient}</CompactDetail>
        <CompactDetail>•</CompactDetail>
        <CompactDetail>{totalTimer}</CompactDetail>
      </div>
      <CompactDetail className="sm:hidden">{appointment.room?.name}</CompactDetail>
      <CompactDetail className="sm:hidden col-span-2">{requestingName}</CompactDetail>
      <CompactDetail className="sm:hidden col-span-2 truncate">{appointment.patient}</CompactDetail>
    </>
  )

  const menuItems = (
    <>
      {onEdit && (
        <MenuItem onClick={handleEdit}>
          <PencilAltIconSmall className="mr-3 h-5 w-5" /> Edit
        </MenuItem>
      )}
      {onComplete && (
        <MenuItem onClick={handleComplete}>
          <CheckCircleIconSmall className="mr-3 h-5 w-5" /> Complete
        </MenuItem>
      )}
      {onSplit && (
        <>
          <MenuDivider />
          <MenuItem onClick={handleSplit}>
            <DuplicateIconSmall className="mr-3 h-5 w-5" /> Split
          </MenuItem>
        </>
      )}
      {onDelete && (
        <>
          <MenuDivider />
          <MenuItem onClick={handleDelete}>
            <TrashIconSmall className="mr-3 h-5 w-5" /> Delete
          </MenuItem>
        </>
      )}
    </>
  )

  const menu = (
    <>
      <Menu className="hidden sm:block ml-1" size="normal">
        {menuItems}
      </Menu>
      <Menu className="sm:hidden ml-1" size="small">
        {menuItems}
      </Menu>
    </>
  )

  const complete = (
    <button
      type="button"
      className={`${
        scheduled && 'invisible'
      } flex items-center text-gray-500 hover:text-gray-800 focus:outline-none focus:text-gray-800`}
      onClick={handleComplete}
    >
      <CheckIconSmall className="h-6 w-6 sm:h-7 sm:w-7" />
    </button>
  )

  const controls = (
    <div className="flex items-center flex-row-reverse normal-case">
      {menu}
      {complete}
    </div>
  )

  const ListItem: React.FC<HTMLAttributes<HTMLElement>> = ({children, ...props}) => (
    <li className="relative">
      <div
        className={`bg-white shadow sm:rounded-md border-b-4 ${appointment.pendingPrompt && 'opacity-50'}`}
        {...props}
        onDoubleClick={handleEdit}
      >
        {appointment.parentId && <div className="sm:rounded-l-md w-3 absolute left-0 top-0 h-full bg-gray-400" />}
        <div className="relative">{children}</div>
      </div>
      <div
        className={`overflow-hidden sm:rounded-md absolute inset-0 pointer-events-none h-full ${
          appointment.parentId && 'pl-3'
        }`}
      >
        {appointment.length && (
          <div className="flex flex-col-reverse h-full">
            {appointment.completed ? (
              <ProgressBar progress={appointment.totalDuration / (appointment.length * 60)} scheduled={scheduled} />
            ) : (
              <Progress start={appointment.start} length={appointment.length} scheduled={scheduled} />
            )}
          </div>
        )}
      </div>
    </li>
  )

  const PriorityVisual: React.FC = () => (
    <div className="absolute z-auto top-1/2 left-0 transform -translate-y-1/2 -translate-x-4 text-red-700">
      <ExclamationCircleIconSmall className={`${compact ? 'w-7 h-7' : 'w-9 h-9'}`} />
    </div>
  )

  const MobileInner: React.FC<HTMLAttributes<HTMLElement>> = ({children, className, ...props}) => {
    const [showOverlay, setShowOverlay] = useState(false)
    const handleClick = () => {
      setShowOverlay(true)
    }

    const ref = useRef()
    useClickOutside(ref, () => setShowOverlay(false))

    return (
      <div
        {...props}
        className={`${className} relative px-0 sm:px-2 md:px-4 ${
          compact ? 'h-8' : 'h-auto'
        } w-full h-full focus:outline-none focus:bg-gray-100`}
      >
        <div ref={ref} className="relative w-full h-full" onClick={handleClick}>
          <div className="flex h-full items-center uppercase text-gray-800 space-x-4 px-2 py-2">{children}</div>
          {showOverlay && (
            <div className="absolute inset-x-0 top-0 z-10">
              <div
                className={`w-full h-auto bg-white opacity-90 px-3 py-1 flex-wrap text-gray-800 text-lg ${
                  !appointment.notes && 'italic'
                }`}
                style={{backgroundColor: currentRuleStyle?.backgroundColor}}
              >
                {!appointment.completed && (
                  <div className="flex items-center justify-center space-x-2 h-full w-full">
                    <Button
                      size="large"
                      icon={CalendarIcon}
                      title="Scheduled"
                      disabled={!onPending}
                      onClick={handlePending}
                    />
                    <Button size="large" icon={ChairIcon} title="Seated" disabled={!onSeated} onClick={handleSeated} />
                    <Button
                      size="large"
                      icon={ClockIcon}
                      title="Waiting"
                      disabled={!onWaiting}
                      onClick={handleWaiting}
                    />
                    <Button
                      size="large"
                      icon={ToothIcon}
                      title="Claimed"
                      disabled={!onClaimed}
                      onClick={handleClaimed}
                    />
                    <div />
                    <Button size="large" icon={CheckIcon} onClick={handleComplete} />
                    <Button size="large" icon={PencilAltIcon} onClick={handleEdit} />
                    <Button size="large" icon={TrashIcon} onClick={handleDelete} />
                  </div>
                )}
                <div className="mt-3">{appointment.notes ?? 'No appointment notes'}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const Inner: React.FC<HTMLAttributes<HTMLElement>> = ({children, className, ...props}) => {
    const [hovering, setHovering] = useState(false)

    let timeout: number

    const handleMouseEnter = () => {
      if (timeout) window.clearTimeout(timeout)
      timeout = window.setTimeout(() => {
        setHovering(true)
      }, 500)
    }

    const handleMouseLeave = () => {
      if (timeout) window.clearTimeout(timeout)
      setHovering(false)
    }

    useEffect(() => {
      return () => {
        if (timeout) window.clearTimeout(timeout)
      }
    }, [timeout])

    const gridSettings =
      'grid grid-rows-2 grid-cols-6 grid-flow-col gap-y-0 gap-x-3 md:gap-x-4 sm:grid-rows-none sm:grid-cols-12'

    return (
      <div
        {...props}
        className={`${className} relative px-1 sm:px-2 md:px-4 ${
          compact ? 'h-8' : 'h-auto py-1'
        } w-full h-full focus:outline-none focus:bg-gray-100`}
      >
        <div className="relative w-full" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div className={`${gridSettings} h-full items-center uppercase text-gray-800`}>{children}</div>
          {hovering && (
            <div className={`absolute inset-0 ${gridSettings} pointer-events-none`}>
              <div className="col-span-5 sm:col-span-11 row-span-full">
                <div
                  className={`w-full h-full bg-white opacity-90 px-3 flex items-center ${
                    compact ? 'truncate' : 'flex-wrap'
                  } text-gray-800 text-lg ${!appointment.notes && 'italic'}`}
                  style={{backgroundColor: currentRuleStyle?.backgroundColor}}
                >
                  {appointment.notes ?? 'No appointment notes'}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const compactView = (
    <ListItem
      {...props}
      style={{
        // borderColor: currentRuleStyle?.borderColor,
        backgroundColor: currentRuleStyle?.backgroundColor,
      }}
    >
      <Inner className="hidden sm:block">
        {provider}
        {time}
        {tags}
        {compactDetails}
        {!appointment.completed && controls}
      </Inner>
      <MobileInner className="block sm:hidden">
        <MobileTextGroup className="w-20 text-center">
          <p className="font-bold text-base tracking-wide">{providerText}</p>
          <p>{timeText}</p>
        </MobileTextGroup>
        {mobileTags}
        <MobileTextGroup>
          <p>{roomText}</p>
          <p>{requestingName}</p>
        </MobileTextGroup>
        <MobileTextGroup>
          <p>{patientText}</p>
          <p>{totalTimerText}</p>
        </MobileTextGroup>
      </MobileInner>
      {appointment.important && <PriorityVisual />}
    </ListItem>
  )

  const defaultView = (
    <ListItem
      {...props}
      style={{
        // borderColor: currentRuleStyle?.borderColor,
        backgroundColor: currentRuleStyle?.backgroundColor,
      }}
    >
      <Inner className="hidden sm:block">
        {time}
        {tags}
        {provider}
        {roomAndRequesting}
        {patient}
        {!appointment.completed && controls}
      </Inner>
      <MobileInner className="block sm:hidden">
        <MobileTextGroup className="w-20 text-center">
          <p className="font-bold text-base tracking-wide">{timeText}</p>
          <p>{providerText}</p>
        </MobileTextGroup>
        {mobileTags}
        <MobileTextGroup>
          <p>{roomText}</p>
          <p>{requestingName}</p>
        </MobileTextGroup>
        <MobileTextGroup>
          <p>{patientText}</p>
          <p>{totalTimerText}</p>
        </MobileTextGroup>
      </MobileInner>
      {appointment.important && <PriorityVisual />}
    </ListItem>
  )

  if (compact) return compactView

  return defaultView
}
