import React, {useMemo, useState, useEffect, useContext} from 'react'
import {useQuery, useSubscription, gql, useMutation} from '@apollo/client'

import {
  Queue,
  filterAppointments,
  sortAppointments,
  Empty,
  hydrateAppointment,
  QueueHeading,
  AppointmentEditorProps,
  AppointmentEditor,
} from '../appointments'
import {Skeleton} from '..'
import {Appointment, AppointmentEvent, getStatMetricDisplay, isAdmin, Status} from '../../models'
import {AppContext} from '../../context'
import {DragDropContext} from 'react-beautiful-dnd'
import {APPOINTMENT_EVENT_SELECT_FRAGMENT, APPOINTMENT_SELECT_FRAGMENT} from '../../queries'
import {CompleteNewAppointmentSubscription} from '../../graphql/CompleteNewAppointmentSubscription'
import {CompleteNewAppointmentEventSubscription} from '../../graphql/CompleteNewAppointmentEventSubscription'
import {AppointmentEventInput, Role} from '../../graphql/Global'
import SEOData from '../seo'
import {PageHeading} from '../tailwind'
import {RouteComponentProps} from '@reach/router'
import {StatAppointmentsGetStatQuery} from '../../graphql/StatAppointmentsGetStatQuery'
import {StatAppointmentsModifyAppointmentMutation} from '../../graphql/StatAppointmentsModifyAppointmentMutation'

export const GET_APPOINTMENTS = gql`
  query StatAppointmentsGetStatQuery($id: ID!) {
    stat(id: $id) {
      id
      metric
      value
      date
      tag {
        id
        name
      }
      provider {
        id
        name
      }
      appointments {
        ...AppointmentSelectFragment
      }
    }
  }
  ${APPOINTMENT_SELECT_FRAGMENT}
`

export const MODIFY_APPOINTMENT = gql`
  mutation StatAppointmentsModifyAppointmentMutation($input: AppointmentModificationInput!) {
    modifyAppointment(input: $input) {
      ...AppointmentSelectFragment
    }
  }
  ${APPOINTMENT_SELECT_FRAGMENT}
`

export const NEW_APPOINTMENT_SUBSCRIPTION = gql`
  subscription StatAppointmentsNewAppointmentSubscription($tid: String!) {
    newAppointment(tid: $tid) {
      ...AppointmentSelectFragment
    }
  }
  ${APPOINTMENT_SELECT_FRAGMENT}
`

export const NEW_APPOINTMENT_EVENT_SUBSCRIPTION = gql`
  subscription StatAppointmentsNewAppointmentEventSubscription($tid: String!) {
    newAppointmentEvent(tid: $tid) {
      ...AppointmentEventSelectFragment
    }
  }
  ${APPOINTMENT_EVENT_SELECT_FRAGMENT}
`

const StatAppointmentsContent: React.FC<{
  id: string
  referrer?: {
    name: string
    path: string
  }
}> = ({id, referrer}) => {
  const [busy, setBusy] = useState<boolean>(false)
  const [events, setEvents] = useState<Record<string, AppointmentEvent[]>>({})
  const [newAppointments, setNewAppointments] = useState<Appointment[]>([])
  const [appointmentEditorProps, setAppointmentEditorProps] = useState<AppointmentEditorProps>()
  const {error, data, loading, refetch} = useQuery<StatAppointmentsGetStatQuery>(GET_APPOINTMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {id},
  })
  const [modifyAppointment] = useMutation<StatAppointmentsModifyAppointmentMutation>(MODIFY_APPOINTMENT)

  if (error) console.log(error)

  const {stat} = data || {}
  const {metric, provider, tag} = stat || {}
  const title = metric
    ? `${getStatMetricDisplay(metric)}${provider ? ` by ${provider?.name}` : ''}${tag ? ` (${tag?.name})` : ''}`
    : 'Loading...'
  const [startDate, endDate] = id
    .replace(`provider#${provider?.id}#`, '')
    .replace(`tag#${tag?.id}#`, '')
    .replace(`metric#${metric}#`, '')
    .split('#')

  const {currentLocation, user} = useContext(AppContext)
  const tid = user?.tid

  const appointments: Appointment[] = useMemo(
    () =>
      data?.stat.appointments &&
      [...data?.stat.appointments, ...newAppointments]
        .map((appointment: Appointment) => hydrateAppointment(appointment, events))
        .filter((a) => a.locationId === currentLocation?.id),
    [data?.stat.appointments, newAppointments, events, currentLocation?.id]
  )

  const {data: newAppointmentData} = useSubscription<CompleteNewAppointmentSubscription>(NEW_APPOINTMENT_SUBSCRIPTION, {
    variables: {tid},
  })

  useEffect(() => {
    if (!newAppointmentData) return
    const {newAppointment} = newAppointmentData

    const current = newAppointments.find((a) => a.id === newAppointment.id)
    if (current) return

    setNewAppointments([...newAppointments, newAppointment])
  }, [newAppointmentData, newAppointments])

  const {data: newAppointmentEventData} = useSubscription<CompleteNewAppointmentEventSubscription>(
    NEW_APPOINTMENT_EVENT_SUBSCRIPTION,
    {variables: {tid}}
  )
  useEffect(() => {
    if (!newAppointmentEventData) return
    const {newAppointmentEvent} = newAppointmentEventData

    const currentEvents = events[newAppointmentEvent.appointmentId]
    const current = currentEvents?.slice().sort((a, b) => b.createdAt - a.createdAt)[0]
    if (current?.createdAt >= newAppointmentEvent.createdAt) return

    setEvents({
      ...events,
      [newAppointmentEvent.appointmentId]: currentEvents
        ? [...currentEvents, newAppointmentEvent]
        : [newAppointmentEvent],
    })
  }, [newAppointmentEventData, events])

  const complete = useMemo(() => filterAppointments(appointments, Status.COMPLETE), [appointments])
  const sortedComplete = useMemo(() => sortAppointments(complete), [complete])

  const noAppointments = complete.length === 0

  // eslint-disable-next-line @typescript-eslint/no-empty-function, unicorn/consistent-function-scoping
  const handleDragEnd = () => {}

  const handleEditComplete = async (event: AppointmentEventInput) => {
    if (event) {
      setBusy(true)

      await modifyAppointment({
        variables: {
          input: {
            ...event,
            provider: undefined,
            requesting: undefined,
            room: undefined,
            tags: undefined,
          },
        },
      })

      await refetch()

      setBusy(false)
    }
  }

  const handleAppointmentEditorDismissal = async (event: AppointmentEventInput) => {
    await handleEditComplete(event)
    setAppointmentEditorProps(undefined)
  }

  const handleEdit = (appointment: Appointment) => {
    setAppointmentEditorProps({
      appointment,
      onDismiss: handleAppointmentEditorDismissal,
    })
  }

  const canEditCompletedAppointment = isAdmin(user) || user.roles.includes(Role.DOCTOR)

  return (
    <div>
      <SEOData title={title} />
      <PageHeading title={title} breadcrumbs={[{name: 'Home', path: '/'}, referrer]} />
      {loading || busy ? (
        <Skeleton />
      ) : (
        <>
          <DragDropContext onDragEnd={handleDragEnd}>
            <QueueHeading>
              Appointments{startDate ? ` from ${startDate}${endDate ? ` to ${endDate}` : ''}` : ''}
            </QueueHeading>
            {!noAppointments && (
              <Queue compact fixed status={Status.COMPLETE} data={sortedComplete} onEdit={handleEdit} />
            )}
            {noAppointments && <Empty title={`No related appointments at ${currentLocation?.name}`} />}
          </DragDropContext>

          {appointmentEditorProps && (
            <AppointmentEditor
              readonly={!canEditCompletedAppointment}
              title="View appointment"
              subtitle="View this completed appointment"
              {...appointmentEditorProps}
            />
          )}
        </>
      )}
    </div>
  )
}

export interface StatAppointmentsPageProps extends RouteComponentProps {
  statId?: string
  referrer?: {
    name: string
    path: string
  }
}

const StatAppointmentsPage: React.FC<StatAppointmentsPageProps> = ({statId, referrer}) => {
  const id = decodeURIComponent(statId)
  return <StatAppointmentsContent id={id} referrer={referrer} />
}

export default StatAppointmentsPage
