const shorthandHexRegex = /^#?([\da-f])([\da-f])([\da-f])$/i
const longhandHexRegex = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i

export interface Color {
  r: number
  g: number
  b: number
}

export const isValidHex = (hex: string): boolean => shorthandHexRegex.test(hex) || longhandHexRegex.test(hex)

export const hexToRgb = (hex: string): Color => {
  hex = hex.replace(shorthandHexRegex, (m, r, g, b) => {
    return `${r}${r}${g}${g}${b}${b}`
  })

  const result = longhandHexRegex.exec(hex)
  return result
    ? {
        r: Number.parseInt(result[1], 16),
        g: Number.parseInt(result[2], 16),
        b: Number.parseInt(result[3], 16),
      }
    : undefined
}

export const luminance = (r: number, g: number, b: number): number => {
  const a = [r, g, b].map((v) => {
    v /= 255
    return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4
  })
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

export const shadeColor = (color: string, percent: number): string => {
  let R = Number.parseInt(color.slice(1, 3), 16)
  let G = Number.parseInt(color.slice(3, 5), 16)
  let B = Number.parseInt(color.slice(5, 7), 16)

  R = Number.parseInt(((R * (100 + percent)) / 100).toString(), 10)
  G = Number.parseInt(((G * (100 + percent)) / 100).toString(), 10)
  B = Number.parseInt(((B * (100 + percent)) / 100).toString(), 10)

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16)
  const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16)
  const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16)

  return '#' + RR + GG + BB
}

export const isAccessible = (color1: string, color2 = '#FFFFFF'): boolean => {
  const color1rgb = hexToRgb(color1)
  const color2rgb = hexToRgb(color2)

  // calculate the relative luminance
  const color1luminance = luminance(color1rgb.r, color1rgb.g, color1rgb.b)
  const color2luminance = luminance(color2rgb.r, color2rgb.g, color2rgb.b)

  // calculate the color contrast ratio
  const ratio =
    color1luminance > color2luminance
      ? (color2luminance + 0.05) / (color1luminance + 0.05)
      : (color1luminance + 0.05) / (color2luminance + 0.05)

  return ratio < 0.33333
}
