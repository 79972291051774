import React, {useState} from 'react'
import Layout from './layout'
import Auth from './auth'
import SEOData from './seo'
import {useInterval, usePersistedState} from '../hooks'
import {Modal} from './tailwind'
import Router from './router'

const handleDismiss = () => {
  // do nothing
}

const handleRefreshClick = () => {
  location.reload()
  return false
}

const Page: React.FC<{title: string}> = ({title, children}) => {
  const [version, setVersion] = usePersistedState<string>('version')
  const [newVersion, setNewVersion] = useState(false)

  // Poll for app updates
  useInterval(
    async () => {
      const response = await fetch('/version', {headers: {pragma: 'no-cache', 'cache-control': 'no-cache'}})
      const versionText = await response.text()

      if (versionText !== version) {
        // Don't show the notification if version has never been persisted
        setNewVersion(Boolean(version))
        setVersion(versionText)
      }
    },
    1000 * 60 * 1, // poll every minute
    true
  )

  return (
    <Auth>
      <Layout>
        <SEOData title={title} />
        <Router>{children}</Router>
        {newVersion && (
          <Modal onDismiss={handleDismiss}>
            <div className="p-4">
              <div className="flex items-center">
                <div className="flex-1 flex justify-between">
                  <div className="flex-1 flex items-center space-x-2 text-base leading-5 font-medium text-gray-900 whitespace-nowrap">
                    <span>🎉</span>
                    <span>New version available:</span> <span className="text-secondary">v{version}</span>
                  </div>
                  <div className="flex space-x-3">
                    <button
                      type="button"
                      className="ml-3 flex-shrink-0 text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                      onClick={handleRefreshClick}
                    >
                      Update
                    </button>
                  </div>
                </div>
                <div className="ml-4 flex-shrink-0 flex" />
              </div>
            </div>
          </Modal>
        )}
      </Layout>
    </Auth>
  )
}

export default Page
